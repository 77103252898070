import React from 'react'
export default function Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M509.054,116.242l-69.002-68.669c-3.9-3.882-10.207-3.882-14.107,0l-62.024,61.725l-62.024-61.725 c-3.9-3.882-10.207-3.882-14.107,0l-69.002,68.669c-1.886,1.877-2.946,4.428-2.946,7.088v76.002H91.851 c-3.099,0-6.021,1.436-7.915,3.888L3.259,307.697c-0.214,0.278-0.411,0.563-0.592,0.855C1.017,310.336,0,312.714,0,315.335 v142.003c0,5.523,4.478,10,10,10h492c5.522,0,10-4.477,10-10V123.33C512,120.67,510.94,118.119,509.054,116.242z M235.84,179.331 h58.314c5.522,0,10-4.477,10-10s-4.478-10-10-10H235.84v-31.845l59.001-58.716l59.001,58.716v71.846H235.84V179.331z M274.006,447.338H20v-23.001h159.291c5.522,0,10-4.477,10-10s-4.478-10-10-10H20v-19h41.487c5.522,0,10-4.477,10-10 s-4.478-10-10-10H20v-40.001h254.006V447.338z M30.353,305.334l66.411-86.002h248.866l-66.53,86.002H30.353z M354.821,393.185 l-12.949,24.228v-48.455L354.821,393.185z M348.556,339.032h35.209l-17.604,32.938L348.556,339.032z M363.997,447.338h-15.44 l17.604-32.938l17.604,32.938H363.997z M390.449,417.413L377.5,393.185l12.949-24.228V417.413z M438.01,447.338h-27.562V329.032 c0-5.523-4.478-10-10-10h-68.577c-5.522,0-10,4.477-10,10v118.306h-27.865V318.751l71.975-93.04l72.029,93.455V447.338z M492,152.331h-32.655c-5.522,0-10,4.477-10,10s4.478,10,10,10H492v29.001h-56.047c-5.522,0-10,4.477-10,10s4.478,10,10,10H492 v226.005h-33.989V315.76c0-2.208-0.731-4.355-2.079-6.104l-81.223-105.384c-0.104-0.134-0.218-0.254-0.326-0.382 c-0.121-0.186-0.253-0.361-0.386-0.538v-75.865l59.001-58.716L492,127.486V152.331z" />
      <path d="M211.803,404.337h-0.131c-5.522,0-10,4.477-10,10s4.478,10,10,10h0.131c5.522,0,10-4.477,10-10 S217.325,404.337,211.803,404.337z" />
      <path d="M403.572,201.332h-0.131c-5.522,0-10,4.477-10,10s4.478,10,10,10h0.131c5.522,0,10-4.477,10-10 S409.095,201.332,403.572,201.332z" />
    </svg>
  )
}
