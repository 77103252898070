import api from '../mixins/apiService'

const postComment = (type, owner, refId, comment) => api.post(`/comment/${type}/${refId}`, { comment })

const getComments = (type, owner, refId) => api.get(`/comment/${type}/${refId}`)

const deleteComment = id => api.del(`/comment/${id}`)

const denounceComment = (id, detail) => api.post(`/comment/denounce/${id}`, { detail })

export default { getComments, postComment, deleteComment, denounceComment }
