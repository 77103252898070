import React from 'react'
export default function Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001" {...props}>
      <path d="M95.999,162.553c-5.508,0-10,4.492-10,10.002c0,5.508,4.492,10,10,10s10-4.492,10-10 C105.999,167.045,101.507,162.553,95.999,162.553z" />
      <path d="M95.999,351.517c-5.508,0-10,4.492-10,10s4.492,10,10,10s10-4.492,10-10S101.507,351.517,95.999,351.517z" />
      <path d="M256.001,162.553c-5.509,0-10.001,4.492-10.001,10.002c0,5.508,4.492,10,10.001,10c5.508,0,10-4.492,10-10 C266.001,167.045,261.509,162.553,256.001,162.553z" />
      <path d="M256.001,351.517c-5.509,0-10.001,4.492-10.001,10s4.492,10,10.001,10c5.508,0,10-4.492,10-10 S261.509,351.517,256.001,351.517z" />
      <path d="M416.002,162.553c-5.508,0-10,4.492-10,10.002c0,5.508,4.492,10,10,10s10-4.492,10-10 C426.002,167.045,421.51,162.553,416.002,162.553z" />
      <path d="M416.002,351.517c-5.508,0-10,4.492-10,10s4.492,10,10,10s10-4.492,10-10S421.51,351.517,416.002,351.517z" />
      <path d="M10.236,446.001H10c-5.522,0-10,4.479-10,10c0,5.523,4.478,10,10,10h0.236c5.522,0,10-4.477,10-10 C20.236,450.48,15.759,446.001,10.236,446.001z" />
      <path d="M502.001,446.001h-0.236c-5.523,0-10,4.479-10,10c0,5.523,4.477,10,10,10h0.236c5.522,0,10-4.477,10-10 C512.001,450.48,507.523,446.001,502.001,446.001z" />
      <path d="M502,214.049c5.522,0,10-4.477,10-10v-62.989c0-5.521-4.478-10-10-10h-26.643l0.642-22.281 c0.076-2.668-0.915-5.254-2.756-7.186l-50-52.49c-1.887-1.982-4.504-3.104-7.24-3.104c-2.736,0-5.354,1.121-7.24,3.104l-50,52.49 c-1.841,1.932-2.832,4.518-2.756,7.186l0.642,22.281h-41.293l0.642-22.281c0.076-2.668-0.915-5.254-2.756-7.186l-50-52.49 c-1.889-1.982-4.506-3.103-7.242-3.103c-2.736,0-5.354,1.121-7.24,3.104l-50,52.49c-1.841,1.932-2.832,4.518-2.756,7.186 l0.642,22.281h-41.291l0.641-22.281c0.076-2.668-0.915-5.254-2.756-7.186l-50-52.49c-1.887-1.983-4.505-3.104-7.241-3.104 c-2.736,0-5.354,1.121-7.24,3.104l-50,52.49c-1.841,1.932-2.832,4.518-2.756,7.186l0.641,22.281H10c-5.522,0-10,4.479-10,10 v62.988c0,5.523,4.478,10,10,10h29.032l3.05,105.975H10c-5.522,0-10,4.478-10,10v62.988c0,5.523,4.478,10,10,10h34.47 l1.237,42.988h-6.74c-5.522,0-10,4.478-10,10c0,5.523,4.478,10,10,10h434.067c5.522,0,10-4.477,10-10c0-5.522-4.477-10-10-10 h-6.741l1.237-42.988H502c5.522,0,10-4.477,10-10v-62.988c0-5.522-4.478-10-10-10h-32.081l3.05-105.975H502z M474.781,151.061H492 v42.988h-18.456L474.781,151.061z M20,194.048v-42.988h17.22l1.237,42.988H20z M20,383.012v-42.988h22.657l1.237,42.988H20z M314.779,151.061h42.444l1.237,42.988h-44.918L314.779,151.061z M312.967,214.048h46.068l3.05,105.975h-52.168L312.967,214.048z M309.342,340.024h53.318l1.237,42.988h-55.793L309.342,340.024z M154.778,151.061h42.443l1.237,42.988h-44.917L154.778,151.061z M152.966,214.048h46.067l3.05,105.975h-52.167L152.966,214.048z M149.341,340.024h53.318l1.236,42.988h-55.792L149.341,340.024z M126.283,446.001H65.715l-9.601-333.631l39.885-41.871l39.885,41.871L126.283,446.001z M146.291,446.001l1.237-42.989h56.943 l1.238,42.989H146.291z M286.284,446.001h-60.567l-9.602-333.631L256,70.498l39.885,41.871L286.284,446.001z M306.292,446.001 l1.237-42.989h56.943l1.238,42.989H306.292z M446.285,446.001h-60.567l-9.601-333.631l39.885-41.871l39.885,41.871 L446.285,446.001z M492,340.024v42.988h-23.893l1.236-42.988H492z" />
    </svg>
  )
}
