import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// styles
import styles from "assets/jss/material-kit-react/views/landingPageSections/aboutStyle.js";
// img
import logo from "assets/img/porteirabertaLOGOCOLOR.png";

const useStyles = makeStyles(styles);

export default function TermsSection() {
  const classes = useStyles();
  const navImageClasses = classNames(classes.imgGallery);
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem align="center">
        <h2 className={classes.title}>Termos e Condições Gerais de Uso da Porteira Aberta</h2>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} justify="right">
            <h5 className={classes.description}>
            Seja bem-vindo! Aqui se encontram os Termos e Condições Gerais de Uso (T&C) da nossa plataforma,
            neste, usuários do site (compradores, vendedores e anunciantes) encontrarão as informações necessárias para uma boa utilização de nosso website.
            O Usuário deverá ler, certificar-se de haver entendido e aceitar todas as condições estabelecidas nos Termos e condições gerais e nas Políticas de privacidade,
            antes de seu cadastro como Usuário da Porteira Aberta.
            </h5>

            <h5 className={classes.description}>
            Abaixo vocês encontrarão uma lista com as principais informações sobre a Porteira Aberta, para que possam utilizar corretamente nossa plataforma.
            </h5>

            <ol className={classes.description}>
              <li> Porteira Aberta</li>
              <li> Modificações dos Termos e condições gerais </li>
              <li> Vedações de Uso </li>
              <li> Funcionamento da Plataforma </li>
              <li> Cadastro </li>
              <li> Anunciando </li>
              <li> Relações com Terceiros</li>
              <li> Propriedade Intelectual</li>
            </ol>

            <h5 className={classes.title}>
              1. Porteira Aberta
            </h5>

            <h5 className={classes.description}>
            Ao utilizar de nosso website, você estará utilizando uma plataforma da Porteira Aberta Soluções Tecnologicas, inscrita no CNPJ/MF sob o nº x. Para quaisquers duvidas
             quanto a este T&C ou dificuldades no uso da nossa plataforma, você poderá falar conosco via central de atendimento.
            </h5>

            <h5 className={classes.title}>
              2. Modificações dos Termos e condições gerais 
            </h5>

            <h5 className={classes.description}>
              A Porteira Aberta se reserva o direito de aprimorar as funcionalidades dos Serviços e de
            implementar novas tecnologias. Assim, os Termos de Uso poderão ser alterados, a qualquer
            tempo, exceto em caso de vedação legal, para incluir as modificações implementadas. Ao
            continuar a utilizar os nossos Serviços após alterados os Termos de Uso, você concorda com
            as alterações realizadas e vigentes à época do acesso
            </h5>

            <h5 className={classes.title}>
              3. Vedações de Uso
            </h5>

            <h5 className={classes.description}>
              Os usuários de nossa plataforma não devem inserir, transmitir, difundir ou disponibilizar a terceiros por meio do Site qualquer tipo de material ou informação que
             sejam contrários à legislação vigente, à moral, à ordem pública, a este T&C, às políticas da Porteira Aberta e às Condições Particulares aplicáveis. 
             Dentre outros, não é permitido (i) "spam", "e-mail de correntes", "marketing piramidal" e publicidade fora das áreas concebidas para tal uso;
            (ii) conteúdo falso, ambíguo, inexato, ou que possam induzir a erro eventuais receptores de referida informação; (iii) conteúdo que implique 
            em violação ao sigilo das comunicações e à privacidade; (iv) senhas de acesso às distintas utilidades e/ou conteúdo do Site que sejam de 
            titularidade de outros usuários.
            </h5>

            <h5 className={classes.title}>
              4. Funcionamento da Plataforma
            </h5>

            <h5 className={classes.description}>
            Nossa plataforma oferece um espaço online para aproximar as pessoas que queiram vender e comprar produtos novos e usados através de anúncio de seus produtos. Algumas
             ferramentas poderão ser pagas num futuro próximo, assim como contratar opções para destaque nos seus anúncios, entre outras funcionalidades. A plataforma oferece filtros de
            categorias para aproximar vendedores e compradores de uma mesma região, facilitando assim o encontro entre as mesmas.
            </h5>
            
            <h5 className={classes.description}>
            Até o presente momento, a Porteira Aberta não presta serviços de consultoria ou intermediação, e nem é proprietária dos produtos e serviços oferecidos nos anúncios, não guarda posse
             e não intervém na definição dos preços. Qualquer compra e venda ou contratação de serviços se dá entre os usuários, sem envolvimento da Porteira Aberta. Se algo der errado em sua transação, qualquer
            indenização deverá ser reclamada com o outro usuário com quem você negociou.
            </h5>
            
            <h5 className={classes.description}>
            A responsabilidade da Porteira Aberta fica então restrita à disponibilização do espaço e das ferramentas que buscamos sempre melhorar para permitir um ambiente de negócios saudável
             e favorável. Sendo assim, a responsabilidade pela realização de anúncios e seu conteúdo, sobre os produtos e serviços oferecidos e sobre a realização e sucesso da transação
              caberá sempre e exclusivamente aos usuários, portanto recomendamos que Você (usuário) leia nossas dicas e procure sempre a nossa Central de Ajuda caso enfrente qualquer dificuldade ou
               tenha dúvidas sobre como proceder e fazer suas transações.
            </h5>

            <h5 className={classes.title}>
              5. Cadastro
            </h5>

            <h5 className={classes.description}>
            Ao utilizar qualquer serviço da Porteira Aberta, você é responsável por manter a confidencialidade de sua conta e senha e restringir o acesso ao seu computador, e responsabiliza-se
             por todas as atividades que ocorram com sua conta ou sua senha. Se tiver menos de 18 anos, você somente poderá utilizar da Porteira Aberta com a participação de um de seus pais ou responsável. A Porteira
             Aberta reserva-se o direito de recusar o serviço, encerrar contas, remover ou editar conteúdo, ou cancelar pedidos a seu critério exclusivo.
            </h5>

            <h5 className={classes.description}>
              Ao criar uma conta em nosa plataforma, Você declara que todas as informações fornecidas são verdadeiras e assume a responsabilidade
             de mantê-las atualizadas. Para proteção de sua conta, sua senha deve ser mantida em sigilo e não deve ser compartilhada, pois as atividades realizadas no Site com o uso de 
             sua conta serão sempre de sua responsabilidade. Não será permitida a transferência, cessão, aluguel ou vendada conta. Confira AQUI como criar, excluir, alterar ou resolver problemas 
             na sua conta.
            </h5>

            <h5 className={classes.description}>
            As Informações da Conta servem para contato de potenciais compradores e da Porteira Aberta, que poderá enviar divulgações sobre promoções, pacotes, novidades ou
             outras informações sobre a Porteira Aberta. Os e-mails enviados para seu endereço cadastrado serão considerados como entregues a Você. As 
             informações relacionadas às utilidades e ferramentas do Site ou outras informações adicionais que a lei exigir poderão ser enviadas por e-mail ao endereço especificado nas
            Informações da Conta, pelos demais meios eletrônicos disponíveis, como notificações via push ou por meio físico por escrito, por correios ao endereço indicado nas Informações
            da Conta. Você tem o direito de optar por deixar de receber tais e-mails, mas caso o cancelamento impossibilite a prestação dos serviços, a Porteira Aberta reserva-se o direito de cancelar
            sua Conta.
            </h5>

            <h5 className={classes.description}>
            Use sua conta com consciência, pois tanto ela como seus anúncios poderão ser suspensos ou excluídos pela Porteira Aberta e Você poderá ser suspenso, sem aviso prévio, em caso de falsidade
            nas Informações da Conta, mau uso, violação da legislação, da moral, da ordem pública, destes T&C e das Condições Particulares que sejam aplicáveis, bem como utilização do Site
            para atividades ilícitas ou imorais, pela falta de pagamento, ou qualquer atividades que, a critério da Porteira Aberta, não esteja de acordo com suas políticas internas. Esta análise da 
            Porteira Aberta não acarreta compromisso, garantia, obrigação ou responsabilidade da Porteira Aberta quanto à veracidade das informações e conteúdo inseridos pelos usuários.
            </h5>

            <h5 className={classes.title}>
              6. Anunciando
            </h5>

            <h5 className={classes.description}>
              A Porteira aberta possui uma sessão de regras e dicas, verifique-as antes de anunciar quaisquer produtos dentro da Porteira Aberta. As regras e dicas são de suma importância para que 
              o seu anúncio possua uma qualidade satisfatória, e relevância para possiveis compradores.
            </h5>
            <h5 className={classes.description}>
              Para realizar um anúncio, o usuário deverá preencher todas as informações de seu produto ou serviço, estado e demais informações. Tais informações devem ser atualizadas sempre que 
              ocorreram modificações significativas nas condições do produto anunciado. 
            </h5>
            <h5 className={classes.description}>
              Todas as informações inseridas no anúncio são de plena responsabilidade e compromisso do anunciante, assim como sua licitude, informações referentes ao anúncio e sua reprodução fidedigna.
            É proibido veicular propagandas com conteúdo obsceno, ofensivo, pornográfico, que promovam qualquer tipo de xenofobismo, racismo ou qualquer espécie de discriminação, ou que atente a quaisquer
            direitos individuais ou coletivos de terceiros. O usuário também não deverá inserir, transmitir, difundir ou colocar à disposição de terceiros, qualquer conteúdo que constitua 
            publicidade ilícita ou desleal; que possa provocar danos aos sistemas informáticos do provedor de acesso, que infrinja direitos de propriedade intelectual e/ou industrial da Porteira
            Aberta ou de terceiros.
            </h5>
            <h5 className={classes.description}>
            Somente poderão ser veiculados no Site anúncios de produtos e/ou serviços permitidos por este T&C e respectivas regras aplicáveis, assim como pela lei vigente. Antes de veicular o seu anúncio,
             é sua responsabilidade se assegurar que ele está em conformidade com as condições encontradas neste T&C, e nas sessão de regras. O usuário/anunciante deverá obrigatoriamente ter a posse e propriedade dos produtos
              e disponibilidade para prestar os serviços, conforme anunciado. O usuário/anunciante deverá informar, de forma clara e completa, condições da entrega e eventuais restrições geográficas.
            </h5>

            <h5 className={classes.description}>
            É de exclusividade do usuário/anunciante a responsabilidade pelo anúncio, pelas informações veiculadas, pelas características e condições do produto ou serviço anunciado, pela sua conduta como usuário,
             pela entrega dos produtos e serviços (ou não entrega) e pelos tributos que possam incidir na transação.
            </h5>

            <h5 className={classes.description}>
            Ao anunciar, o produto será visivel por um periodo de tempo pré determinado, a expiração/renovação poderá ser feita através de determinado requerimento. Se o anuncio não for renovado
            antes da data de expiração, ou desativado por alguma razão, este deverá que ser refeito, inserindo todos os dados novamente.
            </h5>

            <h5 className={classes.title}>
              7. Relações com terceiros
            </h5>

            <h5 className={classes.description}>
            A Porteira Aberta respeita a propriedade intelectual de terceiros. Caso acredite que seu trabalho foi copiado de qualquer forma que constitua uma violação de direitos autorais, 
            siga nossa notificação e procedimento para formular reclamações de violações de direitos autorais que pode ser encontrado na central de atendimento.
            </h5>

            <h5 className={classes.description}>
            A responsabilidade por terceiros que oferecem conteúdos e serviços no Site, que disponibilizam ferramentas de buscas que permitem aos usuários acesso a websites externos,
             dos serviços e do conteúdo dos websites de terceiros acessados por links do Site será exclusiva destes terceiros. Todos os riscos e responsabilidades recairão sobre o usuário ao
            se relacionar com estes terceiros, isentando a Porteira Aberta de qualquer responsabilidade. Caso não concorde, pode optar por não utilizar os conteúdos e serviços de terceiros.
           </h5>

           <h5 className={classes.title}>
              8. Sobre a Propriedade Intelectual
            </h5>

            <h5 className={classes.description}>
            Como uma plataforma de anúncios online, nós não somos responsáveis por infrações aos direitos de propriedade intelectual, direitos de imagem ou à honra dos Usuários em
             decorrência dos conteúdos postados pelos usuários anunciantes, mas removereremos qualquer anúncio que viole direitos desta natureza. Confira nossa política de proteção 
             à propriedade intelectual e como nos notificar de qualquer anúncio que viole estes direitos.
            </h5>

            <h5 className={classes.description}>
            Todos os conteúdos, elementos, estruturas, seleções, ordenações, apresentações do conteúdo e os programas operacionais utilizados por nossa plataforma estão protegidas por direitos
            de propriedade intelectual da Porteira Aberta, portanto, qualquer tipo de copia, tranformação, modificação, desmonte, utilização
            de engenharia reversa, distribuição, alugar, fornecer, web crawling, ou colocar à disposição do público por quaisquer modalidade de comunicação não poderá
            ser realizado sem uma prévia autorização da Porteira Aberta. A compilação de todo conteúdo incluído ou disponibilizado por meio de qualquer meio da Porteira aberta é de propriedade 
            exclusiva da nossa empresa, e é protegida por normas locais, e internacionais relativas a direitos autorais.
            </h5>

        </GridItem>
        <GridItem  xs={12} sm={12} md={8}  align="center"> 
            <img src={logo} alt="..." className={navImageClasses} />
        </GridItem>

      </GridContainer>
      <div>
      </div>
    </div>
  );
}
