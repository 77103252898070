import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'

import { ImportantDevices } from '@material-ui/icons'
import Search from '@material-ui/icons/Search'
import PersonIcon from '@material-ui/icons/Person'
import TractorSvg from '../../assets/icons/tractor.js'

import CustomDropdown from '../../components/CustomDropdown/CustomDropdown.js'
import Button from '../../components/CustomButtons/Button.js'
import CustomInput from '../../components/CustomInput/CustomInput.js'

import styles from '../../assets/jss/material-kit-react/components/headerLinksStyle.js'
import userApi from '../../api/userApi.js'
import store from '../../mixins/store.js'
import utils from '../../mixins/utils.js'

const useStyles = makeStyles(styles)

export default function HeaderLinks() {
  const classes = useStyles()
  const isLogged = utils.isLogged()
  const isPoster = store?.getUserData()?.newsPoster
  console.log(store?.getUserData())
  const [fieldValue, setFieldValue] = React.useState('')
  const doLogout = event => {
    event.preventDefault()
    event.stopPropagation()
    userApi.doLogin()
    store.del(store.keys.token)
    window.location.reload()
  }

  const getBusinessMenuItens = () => {
    const itens = []
    itens.push(
      <a href="/productList" className={classes.dropdownLink} key="searchProducts">
        Buscar Produtos
      </a>
    )
    if (!isLogged) {
      itens.push(
        <Link to="/login" className={classes.dropdownLink} key="login">
          Entrar
        </Link>
      )
    }
    if (isLogged) {
      itens.push(
        <a href="/user/products" className={classes.dropdownLink} key="selfAdvertises">
          Meus Anúncios
        </a>,
        <a href="/product/register" className={classes.dropdownLink} key="productRegister">
          Novo Anúncio
        </a>,
        <a href="/user/message?type=buy" className={classes.dropdownLink} key="message">
          Mensagens de Compras
        </a>,
        <a href="/user/message?type=sell" className={classes.dropdownLink} key="message">
          Mensagens de Vendas
        </a>
      )
    }
    return itens
  }

  const getAdvertiseMenuItens = () => {
    const itens = []
    if (isLogged) {
      itens.push(
        <a href="/product/register" className={classes.dropdownLink} key="productRegister2">
          Novo Anúncio
        </a>
      )
    } else {
      itens.push(
        <Link to="/user/register" className={classes.dropdownLink} key="register">
          Cadastrar-se
        </Link>,
        <Link to="/login" className={classes.dropdownLink} key="login">
          Entrar
        </Link>
      )
    }
    return itens
  }

  const getUserMenuItens = () => {
    const itens = []
    if (!isLogged) {
      itens.push(
        <Link to="/login" className={classes.dropdownLink} key="login">
          Entrar
        </Link>
      )
    } else {
      itens.push(
        <Link to="/user/update" className={classes.dropdownLink} key="profilechange">
          Atualizar Meus Dados
        </Link>,
        <Link to="/user/password" className={classes.dropdownLink} key="passwordchange">
          Trocar Senha
        </Link>,
        <Link to="/user/info" className={classes.dropdownLink} key="profile">
          Meu Perfil
        </Link>
      )
      if (isPoster) {
        itens.push(
          <Link to="/user/newpost" className={classes.dropdownLink} key="newpost">
            Nova Postagem
          </Link>
        )
      }
      itens.push(
        <Link to="" className={classes.dropdownLink} key="home" onClick={doLogout}>
          Sair
        </Link>
      )
    }
    return itens
  }

  const updateField = event => {
    if (event.keyCode === 13) window.location = `/productList?search=${fieldValue}`
  }

  return (
    <div id="navbar" className={classes.navbar}>
      <div className={classes.navigation}>
        <List className={classes.list}>
          <ListItem className={classes.listItem} style={{ marginRight: '1rem' }}>
            <CustomDropdown
              noLiPadding
              buttonText="Anúncie já!"
              buttonProps={{ className: classes.navLink, color: 'transparent' }}
              buttonIcon={ImportantDevices}
              dropdownList={getAdvertiseMenuItens()}
            />
          </ListItem>

          <ListItem className={classes.listItem} style={{ marginRight: '2rem' }}>
            <CustomDropdown
              noLiPadding
              buttonText="Negócios"
              buttonProps={{ className: classes.navLink, color: 'transparent' }}
              svgIcon={TractorSvg}
              dropdownList={getBusinessMenuItens()}
            />
          </ListItem>

          <ListItem id="searchMenuArea" className={classes.listItem}>
            <div>
              <CustomInput
                white
                inputRootCustomClasses={classes.inputRootCustomClasses}
                formControlProps={{
                  className: classes.formControl,
                  style: { paddingTop: '8px' }
                }}
                inputProps={{
                  placeholder: 'Buscar',
                  onChange: e => setFieldValue(e.target.value),
                  onKeyDown: updateField,
                  value: fieldValue,
                  inputProps: {
                    'aria-label': 'Search',
                    className: classes.searchInput
                  }
                }}
              />
              <Button href={`/productList?search=${fieldValue}`} justIcon round color="white">
                <Search className={classes.searchIcon} />
              </Button>
            </div>
          </ListItem>

          <ListItem className={classes.listItem}>
            <CustomDropdown
              noLiPadding
              buttonText="Perfil"
              buttonProps={{ className: classes.navLink, color: 'transparent' }}
              buttonIcon={PersonIcon}
              dropdownList={getUserMenuItens()}
            />
          </ListItem>

          <ListItem className={classes.listItem}>
            <Tooltip
              id="instagram-twitter"
              title="Nos siga no twitter"
              placement={window.innerWidth > 959 ? 'top' : 'left'}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button href="https://twitter.com/AbertaPorteira" target="_blank" color="transparent" className={classes.navLink}>
                <i className={classes.socialIcons + ' fab fa-twitter'} />
              </Button>
            </Tooltip>
          </ListItem>

          <ListItem className={classes.listItem}>
            <Tooltip
              id="instagram-facebook"
              title="Nos siga no facebook"
              placement={window.innerWidth > 959 ? 'top' : 'left'}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button color="transparent" href="https://www.facebook.com/porteirabertabr" target="_blank" className={classes.navLink}>
                <i className={classes.socialIcons + ' fab fa-facebook'} />
              </Button>
            </Tooltip>
          </ListItem>

          <ListItem className={classes.listItem}>
            <Tooltip
              id="instagram-tooltip"
              title="Nos siga no instagram"
              placement={window.innerWidth > 959 ? 'top' : 'left'}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button color="transparent" href="https://www.instagram.com/porteiraberta/" target="_blank" className={classes.navLink}>
                <i className={classes.socialIcons + ' fab fa-instagram'} />
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.listItem}>
            <div>
              <Button href="/news" color="transparent">
                Notícias
              </Button>
            </div>
          </ListItem>
        </List>
      </div>
    </div>
  )
}
