import api from '../mixins/apiService'

const getProductInfo = id => api.get(`/product/${id}`)

const updateProduct = (id, { category, subCategory, title, description, value, quantity, city, estate, pictures, tags }) =>
  api.patch(`/product/${id}`, { category, subCategory, title, description, value, quantity, city, estate, pictures, tags })

const registerProduct = ({ category, subCategory, title, description, value, quantity, city, estate }) =>
  api.post(`/product/register`, { category, subCategory, title, description, value, quantity, city, estate })

const listProducts = ownerId => api.get(`/product/list/${ownerId ? ownerId : ''}`)

const getUploadUrl = (productId, fileName, contentType) => api.get(`/product/upload/${productId}`, { fileName, contentType })
export default { registerProduct, updateProduct, getProductInfo, getUploadUrl, listProducts }
