import React from 'react'
export default function Icon(props) {
  return (
    <svg enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m361.984 492h-21.92s-80.703-71.585-83.663-73.767c-11.887-8.758-29.171-5.987-37.758 5.993-7.117 9.927-18.647 15.854-30.845 15.854-12.195 0-23.725-5.925-30.84-15.849-8.584-11.974-25.855-14.743-37.742-5.999-2.98 2.193-83.673 73.768-83.673 73.768h-20.695c-5.522 0-10 4.477-10 10s4.478 10 10 10h347.136c5.522 0 10-4.477 10-10s-4.478-10-10-10zm-296.357 0s62.956-55.83 65.44-57.658c3.021-2.222 7.456-1.498 9.637 1.542 10.862 15.151 28.467 24.196 47.094 24.196 18.629 0 36.235-9.047 47.1-24.202 2.18-3.041 6.618-3.77 9.639-1.543 2.467 1.817 65.44 57.666 65.44 57.666h-244.35z" />
      <path d="m498.882 132.195-30.746-30.748c-3.904-3.905-10.234-3.905-14.143 0-3.905 3.905-3.905 10.237 0 14.142l30.745 30.748c3.23 3.231 3.23 8.487 0 11.717l-133.99 133.991c-5.186 5.186-12.34 8.032-20.268 8.032-4.58 0-9.057 1.023-13.098 3.21l-12.801-12.801c-3.906-3.905-10.236-3.905-14.143 0-3.905 3.905-3.905 10.237 0 14.143l14.125 14.125c-.812 2.554-1.254 5.273-1.254 8.093-.004 8.393-3.032 15.669-8.95 21.587l-135.627-135.628c5.876-5.873 13.195-9.086 21.582-8.953 3.636.047 7.242-.645 10.603-2.031l14.939 14.939c3.853 3.852 10.289 3.852 14.143 0 3.905-3.905 3.905-10.237 0-14.143l-14.786-14.786c1.549-3.54 2.322-7.379 2.253-11.243-.146-7.92 2.605-15.113 7.745-20.252l133.91-133.911c3.193-3.194 8.523-3.194 11.717 0l30.73 30.733c3.906 3.906 10.236 3.906 14.143 0 3.905-3.905 3.905-10.237 0-14.142l-30.73-30.733c-5.342-5.342-12.446-8.284-20.001-8.284 0 0 0 0-.001 0-7.555 0-14.658 2.942-20 8.284l-133.911 133.911c-9.016 9.015-13.845 21.36-13.599 34.753.055 3.734-3.082 7.012-6.87 6.907-13.475-.195-25.893 4.692-34.952 13.752l-8.128 8.127c-3.852 3.85-3.851 10.292-.001 14.143l149.771 149.77c3.851 3.849 10.292 3.851 14.143 0l7.988-7.989c9.075-9.073 14.008-21.414 13.891-34.661v-.151c0-3.733 3.041-6.77 6.837-6.77 13.314.121 25.679-4.824 34.744-13.889l133.99-133.991c11.026-11.028 11.027-28.972 0-40.001z" />
      <path d="m246.899 229.519c-3.906-3.905-10.236-3.905-14.143 0-3.905 3.905-3.905 10.237 0 14.143l30.374 30.374c3.853 3.852 10.289 3.852 14.143 0 3.905-3.905 3.905-10.237 0-14.143z" />
      <path d="m416.918 144.719c3.631-20.18-6.731-40.282-25.221-49.061-.056-.027-.107-.06-.164-.086-.023-.011-.047-.018-.071-.028-.058-.027-.113-.057-.171-.083-17.35-8.029-37.708-4.266-51.052 8.983-3.721-.174-7.48.197-11.111 1.128-21.074 5.443-40.348 16.454-55.736 31.843-10.585 10.584-19.042 22.882-25.137 36.556-5.241 11.77 2.55 26.147 15.552 27.592 12.658 1.695 25.571 1.352 38.133-.935-.425 12.504.657 25.161 3.331 37.729 3.081 14.483 19.692 22.207 32.781 15.169 41.729-22.495 70.474-62.153 78.866-108.807zm-150.24 37.047c5.081-11.289 12.098-21.452 20.857-30.21 10.022-10.023 22.016-17.823 35.15-22.93-9.254 15.915-15.513 33.208-18.602 51.123-12.175 2.952-24.979 3.655-37.405 2.017zm61.885 54.153c-1.447.797-3.394-.15-3.729-1.726-4.215-19.81-3.9-40.344.897-60.015 4.344-17.797 12.456-34.796 23.545-49.434 3.022-3.976 6.075-7.744 10.497-10.225 4.09-2.295 8.785-3.417 13.47-3.23l-22.097 47.754c-2.319 5.012-.137 10.956 4.876 13.275 4.95 2.29 10.988.068 13.274-4.876l22.004-47.553c5 5.688 7.34 13.479 5.935 21.289-7.309 40.623-32.338 75.155-68.672 94.741z" />
      <path d="m181.287 305.973c-5.522 0-10 4.477-10 10v19.985c0 5.523 4.478 10 10 10s10-4.477 10-10v-19.985c0-5.523-4.477-10-10-10z" />
      <path d="m189.092 395.332c1.953 1.953 4.512 2.929 7.071 2.929s5.118-.976 7.071-2.929l14.586-14.586c3.905-3.905 3.905-10.237 0-14.143-3.906-3.905-10.236-3.905-14.143 0l-14.586 14.586c-3.904 3.905-3.904 10.237.001 14.143z" />
      <path d="m420.97 81.48c3.439 8.255 15.617 7.979 18.646-.455 3.09-8.603-6.549-16.552-14.411-12.031-4.306 2.476-6.11 7.907-4.235 12.486z" />
    </svg>
  )
}
