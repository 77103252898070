import React from 'react'
import Carousel from 'react-slick'
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import PropTypes from 'prop-types'

import styles from 'assets/jss/material-kit-react/views/componentsSections/carouselStyle.js'

const useStyles = makeStyles(styles)

export default function SectionCarousel(props) {
  const { productDetail } = props
  const classes = useStyles()
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  }

  const renderProductPictures = () => {
    if (!productDetail || !productDetail.pictures) return null
    return productDetail.pictures.sort(sortPictures).map(picture => (
      <div key={picture.timestamp}>
        <img src={picture.path} alt={picture.fileName} className="slick-image" />
        <div className="slick-caption"></div>
      </div>
    ))
  }

  const sortPictures = (a, b) => {
    let calc = a.order - b.order
    if (calc === 0) calc = (a.timestamp - b.timestamp) * -1
    return calc
  }

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>{renderProductPictures()}</Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
SectionCarousel.propTypes = {
  productDetail: PropTypes.object
}
