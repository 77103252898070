import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardFooter from 'components/Card/CardFooter.js'

import styles from 'assets/jss/material-kit-react/views/CommentsStyle.js'
import PropTypes from 'prop-types'
import commentApi from '../../../api/commentApi.js'
import profileNoPic from 'assets/img/faces/farmer.jpg'

import TrashIcon from '../../../assets/icons/trash.js'
import DenounceIcon from '../../../assets/icons/denounce.js'
import store from '../../../mixins/store.js'
import userApi from 'api/userApi.js'

const useStyles = makeStyles(styles)
const loadedPosterPictures = {}
export default function CommentsSection(props) {
  const { type, owner, refId, lastLoadTime, deleteComment, denounceComment } = props
  const classes = useStyles()
  const imageClasses = classNames(classes.imgRaised, classes.imgRoundedCircle, classes.imgFluid, classes.imgRoundCustom)
  const commentPagination = 50

  const [loadedComments] = React.useState(commentPagination)
  const [commentList, setCommentList] = React.useState([])

  // const loadMoreComments = () => setLoadedComments(loadedComments + commentPagination)

  const loadComments = async () => {
    if (!owner) return
    const res = await commentApi.getComments(type, owner, refId)
    if (res?.data?.data) {
      res.data.data.sort((a, b) => b.createdAt - a.createdAt)
      setCommentList(res.data.data)
    }
  }

  const renderDeleteIcon = post => {
    if (store?.getUserData()?.id === post.owner)
      return (
        <TrashIcon
          style={{ width: '24px', position: 'absolute', left: '-24px', cursor: 'pointer' }}
          onClick={() => deleteComment(post.id)}
        />
      )
    return (
      <DenounceIcon
        style={{ width: '24px', position: 'absolute', left: '-24px', cursor: 'pointer' }}
        onClick={() => denounceComment(post.id)}
      />
    )
  }

  const loadPosterProfilePic = async post => {
    if (loadedPosterPictures[post.owner] === undefined) {
      loadedPosterPictures[post.owner] = ''
      post.posterPicture = loadedPosterPictures[post.owner]
      const userRes = await userApi.getUserDataInfo(post.owner).catch(err => console.log(err))
      if (userRes?.data?.data) {
        loadedPosterPictures[post.owner] = userRes.data.data.picture || profileNoPic
      }
    } else {
      post.posterPicture = loadedPosterPictures[post.owner]
    }
  }

  const withZero = val => `0${val}`.slice(-2)

  const dateTime = timestamp => {
    const date = new Date(timestamp)
    return `${withZero(date.getDate())}/${withZero(date.getMonth() + 1)}/${date.getFullYear()} as ${withZero(date.getHours())}:${withZero(
      date.getMinutes()
    )}:${withZero(date.getSeconds())}`
  }

  const renderComments = () => {
    const rendered = []
    for (let i = 0; i < Math.min(loadedComments, commentList.length); i++) {
      const post = commentList[i]
      loadPosterProfilePic(post)
      rendered.push(
        <GridContainer key={post.id}>
          <GridItem xs={12} sm={12} md={10} align="center">
            <Card plain>
              <GridContainer align="left">
                <GridItem xs={5} sm={3} md={3} className={classes.itemGrid}>
                  <div className={classes.profile}>
                    <div>
                      <div
                        alt=""
                        className={imageClasses}
                        style={{ backgroundImage: `url('${loadedPosterPictures[post.owner] || profileNoPic}')` }}
                      ></div>
                      {/* <img src={`${loadedPosterPictures[post.owner] || profileNoPic}`} alt="" className={imageClasses} /> */}
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={7} sm={9} md={9}>
                  <h4 className={classes.cardTitle}>
                    {renderDeleteIcon(post)}
                    <div>{post.posterName}</div>
                    <div>
                      <small className={classes.smallTitle}>{post.posterJob}</small>
                    </div>
                    <div>
                      <small className={classes.smallTitle}>{dateTime(post.createdAt)}</small>
                    </div>
                  </h4>
                  <p className={classes.description}>{post.comment}</p>
                  <CardFooter className={classes.justifyCenter}></CardFooter>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      )
    }
    return rendered
  }

  React.useEffect(() => {
    loadComments()
  }, [owner, lastLoadTime])
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Comentários {type === 'product' ? 'sobre o produto' : type === 'news' ? 'sobre a postagem' : ''}</h2>
      <div>{renderComments()}</div>
    </div>
  )
}
CommentsSection.propTypes = {
  userName: PropTypes.string,
  type: PropTypes.string,
  owner: PropTypes.string,
  refId: PropTypes.string,
  lastLoadTime: PropTypes.number,
  deleteComment: PropTypes.func,
  denounceComment: PropTypes.func
}
