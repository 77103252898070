import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'

import 'assets/scss/material-kit-react.scss'

// pages for this product
import Main from 'views/Main/Main.js'
import LandingPage from 'views/LandingPage/LandingPage.js'

import NewsPage from 'views/NewsPage/NewsPage.js'

import PostPage from 'views/PostPage/PostPage.js'

import NewPost from 'views/news/newPost.js'

import Terms from 'views/TermsPage/Terms.js'
import PrivacyPolicyPage from 'views/PrivacyPolicyPage/PrivacyPolicyPage.js'

import LoginPage from './views/user/login.js'
import UserRegister from './views/user/register.js'
import UserUpdate from './views/user/update.js'
import UserPassword from './views/user/password.js'
import ProfilePage from './views/user/info.js'
import MessagePage from 'views/user/messages.js'

import MyProductList from './views/product/myProductList.js'
import ProductEdit from './views/product/editProduct.js'
import ProductRegister from './views/product/registerProduct.js'

import ProductList from './views/product/productList.js'
import ProductInfo from './views/product/productInfo.js'

var hist = createBrowserHistory()

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/user/register" component={UserRegister} />
      <Route exact path="/user/update" component={UserUpdate} />
      <Route exact path="/user/password" component={UserPassword} />
      <Route exact path="/user/info" component={ProfilePage} />
      <Route exact path="/user/info/:id" component={ProfilePage} />
      <Route exact path="/user/products" component={MyProductList} />
      <Route exact path="/product/edit/:id" component={ProductEdit} />
      <Route exact path="/product/register" component={ProductRegister} />

      <Route exact path="/productList" component={ProductList} />
      <Route path="/product/:id" component={ProductInfo} />

      <Route path="/landing" component={LandingPage} />
      <Route path="/terms" component={Terms} />
      <Route path="/privacypolicy" component={PrivacyPolicyPage} />
      <Route path="/profile" component={ProfilePage} />
      <Route path="/user/message" component={MessagePage} />
      <Route path="/news" component={NewsPage} />
      <Route path="/user/newpost" component={NewPost} />
      <Route path="/post/:id" component={PostPage} />
      <Route path="/" component={Main} />
    </Switch>
  </Router>,
  document.getElementById('root')
)
