import { title } from 'assets/jss/material-kit-react.js'

import imagesStyle from 'assets/jss/material-kit-react/imagesStyles.js'

const ContentStyle = {
  section: {
    padding: '70px 0',
    textAlign: 'justify'
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none'
  },
  description: {
    color: '#303030'
  },
  postArea: {
    '& img': {
      maxWidth: '100%'
    }
  },
  ...imagesStyle
}

export default ContentStyle
