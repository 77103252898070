import React from 'react'
export default function Icon(props) {
  return (
    <svg viewBox="-27 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m409.367188 22.679688-37.507813-21.367188c-3.070313-1.75-6.835937-1.75-9.90625 0l-37.507813 21.367188c-30.183593 17.195312-48.929687 49.453124-48.929687 84.191406v83.585937c0 5.527344 4.480469 10.007813 10.003906 10.007813h47.363281v55.703125c0 3.269531 1.578126 6.164062 4.003907 7.988281v74.898438c0 5.527343 4.480469 10.003906 10.007812 10.003906 5.523438 0 10.003907-4.476563 10.003907-10.003906v-72.882813h20.015624v215.808594c0 5.519531-4.492187 10.007812-10.007812 10.007812-5.519531 0-10.007812-4.488281-10.007812-10.007812v-62.875c0-5.527344-4.480469-10.007813-10.003907-10.007813-5.527343 0-10.007812 4.480469-10.007812 10.007813v62.875c0 16.554687 13.464843 30.019531 30.019531 30.019531 16.550781 0 30.019531-13.464844 30.019531-30.019531v-217.824219c2.425781-1.824219 4.003907-4.71875 4.003907-7.988281v-55.703125h47.363281c5.523437 0 10.003906-4.480469 10.003906-10.007813v-83.589843c0-34.734376-18.746094-66.992188-48.929687-84.1875zm-28.453126 223.480468h-28.015624v-45.695312h28.015624zm57.371094-65.710937h-142.761718v-73.582031c0-27.558594 14.878906-53.15625 38.828124-66.800782l32.554688-18.542968 32.554688 18.546874c23.949218 13.640626 38.824218 39.238282 38.824218 66.800782zm0 0" />
      <path d="m190.125 0c-16.554688 0-30.019531 13.464844-30.019531 30.019531v110.40625h-20.015625v-110.40625c0-16.554687-13.464844-30.019531-30.019532-30.019531-16.550781 0-30.019531 13.464844-30.019531 30.019531v110.40625h-20.011719v-110.40625c0-16.554687-13.464843-30.019531-30.019531-30.019531-16.554687 0-30.019531 13.464844-30.019531 30.019531v120.410157c0 27.589843 22.445312 50.035156 50.03125 50.035156h26.019531v55.703125c0 3.269531 1.574219 6.164062 4 7.988281v217.824219c0 16.554687 13.46875 30.019531 30.019531 30.019531 16.554688 0 30.019532-13.464844 30.019532-30.019531v-217.824219c2.429687-1.824219 4.003906-4.71875 4.003906-7.988281v-55.703125h26.015625c27.589844 0 50.035156-22.445313 50.035156-50.035156v-120.410157c0-16.554687-13.46875-30.019531-30.019531-30.019531zm-70.046875 481.980469c0 5.519531-4.488281 10.007812-10.007813 10.007812-5.515624 0-10.003906-4.488281-10.003906-10.007812v-215.808594h20.011719zm4.003906-235.820313h-28.019531v-45.695312h28.019531zm76.046875-95.730468c0 16.554687-13.464844 30.019531-30.019531 30.019531h-120.078125c-16.550781 0-30.019531-13.464844-30.019531-30.019531v-120.410157c0-5.519531 4.488281-10.007812 10.007812-10.007812s10.007813 4.488281 10.007813 10.007812v120.410157c0 5.527343 4.480468 10.007812 10.003906 10.007812h40.027344c5.527344 0 10.007812-4.480469 10.007812-10.007812v-120.410157c0-5.519531 4.488282-10.007812 10.003906-10.007812 5.519532 0 10.007813 4.488281 10.007813 10.007812v120.410157c0 5.527343 4.480469 10.007812 10.007813 10.007812h40.023437c5.527344 0 10.007813-4.480469 10.007813-10.007812v-120.410157c0-5.519531 4.488281-10.007812 10.007812-10.007812 5.515625 0 10.003906 4.488281 10.003906 10.007812zm0 0" />
      <path d="m346.894531 389.085938c2.632813 0 5.203125-1.070313 7.074219-2.933594 1.863281-1.859375 2.933594-4.441406 2.933594-7.074219 0-2.628906-1.070313-5.210937-2.933594-7.074219-1.871094-1.859375-4.441406-2.929687-7.074219-2.929687-2.628906 0-5.210937 1.070312-7.074219 2.929687-1.871093 1.863282-2.929687 4.445313-2.929687 7.074219 0 2.632813 1.058594 5.214844 2.929687 7.074219 1.863282 1.863281 4.445313 2.933594 7.074219 2.933594zm0 0" />
    </svg>
  )
}
