import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <h2 className={classes.title}>Fácil, prático e rápido</h2>
          <h5 className={classes.description}>
            Na Porteira Aberta você se cadastra e anúncia seu produto em apenas alguns cliques, 
            de imediato seu produto poderá ser visto por todo o Brasil, sendo este um público voltado para
            o agronegócio! Um local que nasceu, e cresce para o agronegócio!
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Chat interno"
              description="Achou algo que te interessa? Ao encontrar o produto buscado você pode abrir um chat na própria plataforma, falando diretamente com o proprietário, de forma rapida e segura!"
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Verificação de usuário"
              description="Todos os nossos usuários passam por um processo de verificação inicial, no qual devem inserir dados pessoais para dar mais segurança para compradores e vendedores."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Proximidade"
              description="Na Porteira Aberta, você pode buscar o produto mais próximo de você, com vendedores verificados e avaliados por nossa própria comunidade!"
              icon={LocationOnRoundedIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
