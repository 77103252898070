import React from 'react'
import swal from 'sweetalert2'
import { Redirect } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import { MdAssignment } from 'react-icons/md'
import Loader from '../../components/Loader/Loader.js'
import PropTypes from 'prop-types'
import Header from 'components/Header/Header.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Footer from 'components/Footer/Footer.js'
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress.js'
import styles from 'assets/jss/material-kit-react/views/loginPage.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import RichText from '../../components/RichText/RichText.js'
import image from 'assets/img/colheita.png'
import InputLabel from '@material-ui/core/InputLabel'
import productApi from '../../api/productApi.js'
import utils from '../../mixins/utils.js'
import store from '../../mixins/store.js'
import FormControl from '@material-ui/core/FormControl'
import fullStates from '../../content/state.json'
import fullCities from '../../content/cities.json'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import fullCategories from '../../content/category.json'

const useStyles = makeStyles(styles)

export default function NewProductPage(props) {
  const isLogged = !!store.get(store.keys.token)

  const [progress, setProcess] = React.useState(100 / 3)
  const [step, setStep] = React.useState(1)
  const [cardAnimation, setCardAnimation] = React.useState('cardHidden')
  const [isLoading, setIsLoading] = React.useState(false)
  const [subCategoryValue, setSubCategoryValue] = React.useState('')
  const [categoryValue, setCategoryValue] = React.useState('')
  const [pageTitle, setPageTitle] = React.useState('Vamos anunciar seu produto?')

  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [value, setValue] = React.useState('')
  const [quantity, setQuantity] = React.useState('')
  const [city, setCity] = React.useState('')
  const [estate, setEstate] = React.useState('')
  const [citiesOptions, setCitiesOptions] = React.useState([])
  const [tagOptions, setTagOptions] = React.useState([])
  const [tags, setTags] = React.useState([])

  const estateOptions = fullStates.map(val => val.Sigla)
  const categories = { categoryValue, setCategoryValue, subCategoryValue, setSubCategoryValue }

  const classes = useStyles()
  const { ...rest } = props

  const alert = (msg, type) => swal.fire({ title: '', text: msg, icon: type || 'warning', confirmButtonText: 'OK' })

  const updateTagList = () => {
    if (categoryValue && subCategoryValue) {
      setTagOptions(fullCategories?.[categoryValue]?.subCategorias?.[subCategoryValue] || [])
    }
  }

  const changedState = selectedItem => {
    setCity('')
    setEstate(selectedItem)
    const selectedEstate = fullStates.filter(est => est.Sigla === selectedItem)
    const cities = fullCities.filter(innerCity => innerCity.Estado === selectedEstate?.[0]?.ID).map(({ Nome }) => Nome)
    setCitiesOptions(cities)
  }

  const changedTag = selectedItem => {
    setTags(selectedItem)
  }

  const changedCity = selectedItem => setCity(selectedItem)

  const updatePageTitle = currentStep => {
    if (currentStep === 1) {
      setPageTitle('Vamos anunciar seu produto?')
    } else if (currentStep === 2) {
      setPageTitle('Conte um pouco mais sobre seu produto')
    } else if (currentStep === 3) {
      setPageTitle('Agora falta pouco')
    }
  }

  const previousStep = () => {
    setProcess((100 / 3) * (step - 1))
    setStep(step - 1)
    updatePageTitle(step - 1)
  }

  const saveNewProduct = async () => {
    const productInfo = {
      category: categoryValue,
      subCategory: subCategoryValue,
      title,
      description,
      quantity,
      city,
      estate,
      value
    }
    utils.clearEmptyValues(productInfo)
    setIsLoading(true)
    const res = await productApi.registerProduct(productInfo).catch(console.log)
    if (res?.data?.data) {
      alert('Produto criado com sucesso!\n\nAgora vamos aprimora-lo!', 'success')
      props.history.push(`/product/edit/${res.data.data.id}?tabIndex=3`)
    }
    setIsLoading(false)
  }

  const nextStep = () => {
    if (step === 3) {
      if (!title) return alert('É preciso preencher o nome do produto ou serviço')
      if (!description) return alert('É preciso preencher uma breve descrição sobre o produto ou serviço')
      return saveNewProduct()
    }
    setProcess((100 / 3) * (step + 1))
    setStep(step + 1)
    updatePageTitle(step + 1)
  }
  React.useEffect(() => {
    setTimeout(() => setCardAnimation(''), 700)
  }, [])
  React.useEffect(() => {
    updateTagList()
  }, [subCategoryValue])
  if (!isLogged) return <Redirect to="/login" />

  return (
    <div>
      <Loader isLoading={isLoading}>
        <Header absolute color="transparent" brand="Porteira Aberta" rightLinks={<HeaderLinks />} {...rest} />
        <div
          className={classes.pageHeader}
          style={{ backgroundImage: 'url(' + image + ')', backgroundSize: 'cover', backgroundPosition: 'top center' }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Card className={classes[cardAnimation]}>
                  <form className={classes.form}>
                    <GridItem xs={12} sm={12} md={12} className={classes.marginAuto} align="center">
                      <h2 className={classes.title}>{pageTitle}</h2>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} className={classes.marginAuto} align="center">
                      <CustomLinearProgress variant="determinate" color="success" value={progress} />
                    </GridItem>
                    {step === 1 && (
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto} align="center">
                            <CustomSelect align="center" justify="center" categories={categories} data={fullCategories} />
                            {subCategoryValue !== '' && (
                              <div>
                                <br />
                                <br />
                                <Button simple color="primary" size="lg" onClick={nextStep}>
                                  Avançar
                                </Button>
                              </div>
                            )}
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    )}
                    {step === 2 && (
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto} align="center">
                            <FormControl fullWidth={true}>
                              <Autocomplete
                                multiple
                                options={tagOptions}
                                getOptionLabel={option => option}
                                onChange={(event, newValue) => changedTag(newValue)}
                                value={tags}
                                filterSelectedOptions
                                renderInput={params => (
                                  <TextField {...params} variant="standard" label="Tags" placeholder="Selecione Tags" />
                                )}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <Button simple color="primary" size="lg" onClick={previousStep}>
                              Voltar
                            </Button>
                            <Button simple color="primary" size="lg" onClick={nextStep}>
                              Avançar
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    )}
                    {step === 3 && (
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText="Nome do Produto ou Serviço"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                type: 'text',
                                value: title,
                                onChange: event => {
                                  const val = event.target.value
                                  if (val.length <= 50) setTitle(val)
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <MdAssignment className={classes.inputIconsColor} />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem>
                            <InputLabel className="classes.textArea">Descrição do Produto/Serviço</InputLabel>
                            <RichText value={description} setValue={setDescription} />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Valor do Produto/Serviço"
                              id="value"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{ type: 'number', value: value, onChange: event => setValue(event.target.value) }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Quantidade"
                              id="quantidade"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{ type: 'text', value: quantity, onChange: event => setQuantity(event.target.value) }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth={true} styles={{ marginTop: '1rem' }}>
                              <Autocomplete
                                options={estateOptions}
                                getOptionLabel={item => item?.value || item}
                                onChange={(event, newValue) => changedState(newValue)}
                                value={estate}
                                filterSelectedOptions
                                renderInput={params => (
                                  <TextField {...params} variant="standard" label="Estado" placeholder="Selecione seu Estado" />
                                )}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth={true} styles={{ marginTop: '1rem' }}>
                              <Autocomplete
                                options={citiesOptions}
                                getOptionLabel={item => item?.value || item}
                                onChange={(event, newValue) => changedCity(newValue)}
                                value={city}
                                filterSelectedOptions
                                renderInput={params => (
                                  <TextField {...params} variant="standard" label="Cidade" placeholder="Selecione sua Cidade" />
                                )}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <Button simple color="primary" size="lg" onClick={previousStep}>
                              Voltar
                            </Button>
                            <Button simple color="primary" size="lg" onClick={nextStep}>
                              Salvar
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    )}
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </Loader>
    </div>
  )
}
NewProductPage.propTypes = {
  history: PropTypes.object
}
