import api from '../mixins/apiService'

const doLogin = (email, password) => api.post('user/auth', { email, auth:password })

const doLogout = () => api.del('user/login')

const getUserData = () => api.get(`/user/detail`)

const getUserDataInfo = id => api.get(`/user/detail/${id}`)

const setUserPassword = (password, newPassword, newPasswordCheck) =>
  api.patch(`/user/detail/pwd`, { password, newPassword, newPasswordCheck })

const setUserData = ({ name, document, estate, city, phone, job, email, birthday, picture, description }) =>
  api.patch(`/user/detail`, { name, document, estate, city, phone, job, email, birthday, picture, description })

const registerUser = ({ name, cpf, estate, city, phone, job, email, birthday, password }) =>
  api.post(`/user/register`, { name, cpf, estate, city, phone, job, email, birthday, auth:password })

const getUploadUrl = (fileName, contentType) => api.get(`/user/upload`, { fileName, contentType })

export default { doLogin, doLogout, getUserData, getUserDataInfo, getUploadUrl, setUserData, setUserPassword, registerUser }
