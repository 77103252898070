import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone-uploader'

import 'react-dropzone-uploader/dist/styles.css'
import { Button } from '@material-ui/core'

export default function FileUploader(props) {
  const { url, completedUpload, picture, deleteImage } = props

  const handleChangeStatus = async (event, status) => {
    if (status === 'headers_received') {
      await completedUpload(true, event)
      event.remove()
    }
  }

  return (
    <React.Fragment>
      <div style={{ position: 'relative' }}>
        {picture ? (
          <div
            style={{
              width: '100%',
              height: props.height || 200,
              display: 'inline-block',
              backgroundSize: 'cover',
              position: 'absolute',
              left: '0',
              backgroundImage: `url(${picture})`
            }}
          >
            <Button style={{ color: '#000000' }} onClick={deleteImage}>
              Remover Imagem
            </Button>
          </div>
        ) : (
          <Dropzone
            getUploadParams={url}
            onChangeStatus={handleChangeStatus}
            maxFiles={1}
            multiple={false}
            canCancel={true}
            maxSizeBytes={20971520} //20 Mb
            inputContent={(files, extra) =>
              extra.reject ? 'Você pode enviar somente imagens' : picture ? '' : 'Arraste ou clique para selecionar uma foto'
            }
            accept="image/*"
            styles={{
              dropzoneReject: {
                borderColor: '#F19373',
                transition: '500ms linear',
                backgroundSize: '100%',
                background: 'repeating-linear-gradient(135deg, #F19373, #eeeeee, #d9d9d9 2rem)'
              },
              inputLabel: (files, extra) =>
                extra.reject ? { color: '#A02800' } : extra.active ? { color: '#0000FF' } : { color: '#000000' },
              dropzone: {
                width: '100%',
                height: props.height || 200,
                overflow: 'hidden',
                background: 'repeating-linear-gradient(135deg, #d5d5d5, #eeeeee, #d9d9d9 2rem)',
                backgroundPosition: '100%',
                backgroundSize: '100%',
                transition: '500ms linear',
                display: 'inline-block'
              },
              dropzoneActive: { borderColor: 'green', backgroundSize: '120%', transition: '500ms linear' }
            }}
          />
        )}
      </div>
    </React.Fragment>
  )
}

FileUploader.propTypes = {
  url: PropTypes.func,
  height: PropTypes.number,
  picture: PropTypes.string,
  completedUpload: PropTypes.func,
  deleteImage: PropTypes.func
}
