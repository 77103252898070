import React from 'react'
import PropTypes from 'prop-types'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  blurArea: {
    filter: 'blur(3px)',
    '-webkit-filter': 'blur(3px)'
  }
}))

function Loader({ isLoading, children, style }) {
  const classes = useStyles()
  return (
    <>
      <div className={isLoading ? classes.blurArea : ''} style={style}>
        {children}
      </div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.any,
  style: PropTypes.any
}

export default Loader
