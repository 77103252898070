import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Parallax from 'components/Parallax/Parallax.js'

// Sections for this page
import SearchSection from './Sections/SearchSection.js'
import ShowRoomSection from './Sections/ShowRoomSection.js'
// dynamoDB
// styles
import styles from 'assets/jss/material-kit-react/views/landingPage.js'

const useStyles = makeStyles(styles)

export default function NewsPage(props) {
  const classes = useStyles()
  const { ...rest } = props
  const [subCategoryValues, setSubCategoryValues] = React.useState([])
  const [categoryValues, setCategoryValues] = React.useState([])
  const [searchField, setSearchField] = React.useState('')
  const categories = { subCategoryValues, setSubCategoryValues, categoryValues, setCategoryValues, searchField, setSearchField }
  return (
    <div>
      <Header
        href="/"
        color="transparent"
        brand="Porteira Aberta"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: 'white'
        }}
        {...rest}
      />
      <Parallax small filter image={require('assets/img/mailbox.jpg')}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Blog e notícias</h1>
              <h4>Do interior do Brasil, para sua tela</h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={8} lg={8}>
              <ShowRoomSection categories={categories} />
            </GridItem>
            <GridItem xs={12} sm={6} md={4} lg={4}>
              <SearchSection categories={categories} />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  )
}
