import React from 'react'
import { Redirect } from 'react-router'
import store from './store.js'

export default {
  redirect(path) {
    return <Redirect to={path} />
  },
  isLogged() {
    return !!store.get(store.keys.token)
  },
  addClass(element, className) {
    if (!element || !className) return
    if (element.classList) element.classList.add(className)
    else element.className += ' ' + className
  },
  removeClass(element, className) {
    if (!element || !className) return
    if (element.classList) element.classList.remove(className)
    else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
  },
  sleep: milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds)),
  isDesktop() {
    return window.innerWidth > 1024
  },
  moneyFormat: value => {
    if (!value || isNaN(Number(value))) return '-'
    return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(value))
  },
  isValidCPF: cpf => {
    cpf = cpf.replace(/[^\d]/g, '')
    let baseSum = 0
    let allSum = 0
    let invalid = true
    const values = cpf.split('').map(Number)
    for (var i = 0; i < 9; i++) {
      allSum += values[i]
      invalid = invalid && values[Math.max(i - 1, 0)] === values[i]
      baseSum += (10 - i) * values[i]
    }
    if (((baseSum * 10) % 11) % 10 !== values[9]) return false
    baseSum += allSum + values[9] * 2
    return ((baseSum * 10) % 11) % 10 === values[10] && !invalid
  },
  isValidEmail: email => /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email),
  isDate: input => Object.prototype.toString.call(input) === '[object Date]',
  clearEmptyValues: object => {
    for (const key in object) {
      if (!object[key]) delete object[key]
    }
  },
  isValidCNPJ: cnpj => {
    cnpj = cnpj.replace(/[^\d]+/g, '')
    if (cnpj === '') return false
    if (cnpj.length !== 14) return false
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    )
      return false

    let sizeLength = cnpj.length - 2
    let numbers = cnpj.substring(0, sizeLength)
    let digits = cnpj.substring(sizeLength)
    let soma = 0
    let pos = sizeLength - 7
    for (let i = sizeLength; i >= 1; i--) {
      soma += numbers.charAt(sizeLength - i) * pos--
      if (pos < 2) pos = 9
    }
    let result = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (Number(result) !== Number(digits.charAt(0))) return false

    sizeLength = sizeLength + 1
    numbers = cnpj.substring(0, sizeLength)
    soma = 0
    pos = sizeLength - 7
    for (let i = sizeLength; i >= 1; i--) {
      soma += numbers.charAt(sizeLength - i) * pos--
      if (pos < 2) pos = 9
    }
    result = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (Number(result) !== Number(digits.charAt(1))) return false

    return true
  },
  getExtension: fileName => {
    if (!fileName) return fileName
    return fileName.replace(/.*\.([a-z0-9]{2,4})$/i, '$1').toLowerCase()
  }
}
