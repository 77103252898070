import React from 'react'
import swal from 'sweetalert2'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Parallax from 'components/Parallax/Parallax.js'
import styles from 'assets/jss/material-kit-react/views/landingPage.js'
import newsApi from '../../api/newsApi.js'
import commentApi from 'api/commentApi.js'
import CommentForm from '../product/section/commentForm.js'
import CommentList from '../product/section/commentList'
import ContentSection from './Sections/Content.js'
import Loader from '../../components/Loader/Loader.js'
import utils from '../../mixins/utils.js'
import store from '../../mixins/store.js'
import userApi from '../../api/userApi.js'

const dashboardRoutes = []

const useStyles = makeStyles(styles)

export default function PostPage(props) {
  const classes = useStyles()
  const { ...rest } = props
  const { id } = useParams()

  const [news, setNews] = React.useState({})
  const [lastLoadTime, setLastLoadTime] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(true)
  const [showCommentForm, setShowCommentForm] = React.useState(false)
  const [userName, setUserName] = React.useState('')

  const loadNews = async () => {
    const res = await newsApi.getNews(id)
    if (res?.data?.data) {
      setNews(res.data.data)
      setShowCommentForm(utils.isLogged() && store?.getUserData()?.id !== res.data.data.owner)
      const userRes = await userApi.getUserDataInfo(res.data.data.owner).catch(err => console.log(err))
      if (userRes?.data?.data) {
        setUserName(userRes.data.data.name)
        setLastLoadTime(new Date().valueOf())
      }
    }
    setIsLoading(false)
  }

  const handleSaveCommentError = async error => {
    console.log(error)
    if (error?.data?.msg) {
      await swal.fire({ title: 'Falha!', text: error.data.msg, icon: 'error', confirmButtonText: 'OK' })
    }
  }

  const sendComment = async (msg, setField) => {
    if (!msg || msg.length < 10) return alert('Mensagem muito curta!')
    setIsLoading(true)
    const res = await commentApi.postComment('news', news.owner, id, msg).catch(handleSaveCommentError)
    if (res?.data) {
      await alert('Comentário enviado!', 'success')
      setLastLoadTime(new Date().valueOf())
      setField('')
    }
    setIsLoading(false)
  }

  const denounceComment = async commentId => {
    const result = await swal.fire({
      input: 'textarea',
      inputLabel: 'Deseja realmente denunciar este comentário?',
      inputPlaceholder: 'Descreva aqui o motivo...',
      inputAttributes: {
        'aria-label': 'Descreva aqui o motivo'
      },
      showCancelButton: true,
      confirmButtonText: 'Denunciar'
    })

    if (result.isConfirmed) {
      const detail = result.value
      if (!detail.trim()) return alert('É necessário informar o motivo da denuncia.')
      setIsLoading(true)
      await commentApi.denounceComment(commentId, detail)
      setLastLoadTime(new Date().valueOf())
      alert('Denuncia enviada! Em breve nossa equipe irá fazer uma análise!', 'success')
      setIsLoading(false)
    }
  }

  const deleteComment = async commentId => {
    const result = await swal.fire({
      title: 'Deseja realmente apagar seu comentário?',
      showCancelButton: true,
      confirmButtonText: 'Sim'
    })
    if (result.isConfirmed) {
      setIsLoading(true)
      await commentApi.deleteComment(commentId)
      setLastLoadTime(new Date().valueOf())
      alert('Comentário apagado!', 'success')
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    loadNews()
  }, [])
  return (
    <div>
      <Loader isLoading={isLoading}>
        <Header
          href="/"
          color="transparent"
          routes={dashboardRoutes}
          brand="Porteira Aberta"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{ height: 400, color: 'white' }}
          {...rest}
        />
        <Parallax filter image={require('assets/img/mailbox.jpg')}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>Blog e notícias</h1>
                <h4>Do interior do Brasil, para sua tela</h4>
                <br />
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <ContentSection news={news} />
            <CommentList
              userName={userName}
              owner={news.owner}
              refId={id}
              lastLoadTime={lastLoadTime}
              type="news"
              deleteComment={deleteComment}
              denounceComment={denounceComment}
            />
            {showCommentForm ? <CommentForm sendComment={sendComment} /> : null}
          </div>
        </div>
        <Footer />
      </Loader>
    </div>
  )
}
