import api from '../mixins/apiService'

const getNews = newsId => api.get(`/news/${newsId}`)

const getMyNews = ownerId => api.get(`/news/list/${ownerId}`)

const getAllNews = () => api.get(`/news/list`)

const getNewsByCategory = (category, subCategory) => api.get(`/news/category/${category}${subCategory ? `/${subCategory}` : ''}`)

const patchNews = (newsObject, newsId) => api.post(`/news/${newsId}`, newsObject)

const postNews = newsObject => api.post('/news/register', newsObject)

export default { postNews, patchNews, getNews, getMyNews, getAllNews, getNewsByCategory }
