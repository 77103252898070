import React from 'react'
import Carousel from 'react-slick'
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import PropTypes from 'prop-types'

import styles from 'assets/jss/material-kit-react/views/componentsSections/carouselStyle.js'
import productApi from '../../../api/productApi'
import utils from '../../../mixins/utils.js'
import missingPicture from 'assets/img/missingPicture.png'

const useStyles = makeStyles(styles)
export default function RelatedProducts(props) {
  const { productId } = props
  const [products, setProducts] = React.useState([])
  const classes = useStyles()
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  }
  const containerSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(4, products?.length || 1),
    slidesToScroll: 1,
    autoplay: false,
    initialSlide: 0,
    responsive: [
      { breakpoint: 1700, settings: { slidesToShow: Math.min(3, products?.length || 0), slidesToScroll: 1, infinite: true, dots: false } },
      { breakpoint: 1250, settings: { slidesToShow: Math.min(2, products?.length || 0), slidesToScroll: 1, initialSlide: 2 } },
      { breakpoint: 800, settings: { slidesToShow: Math.min(1, products?.length || 0), slidesToScroll: 1, initialSlide: 2 } }
    ]
  }

  const renderProductPictures = pictures => {
    if (!pictures || !pictures.length) {
      return [
        <div key={new Date().valueOf()}>
          <div style={{ overflow: 'hidden' }}>
            <img src={missingPicture} className="slick-image-categories" alt="" />
            <div className="slick-caption"></div>
          </div>
        </div>
      ]
    }
    return pictures.sort(sortPictures).map(picture => (
      <div key={picture.timestamp}>
        <div style={{ overflow: 'hidden' }}>
          <img src={picture.path} className="slick-image-categories" alt={picture.fileName} />
          <div className="slick-caption"></div>
        </div>
      </div>
    ))
  }

  const sortPictures = (a, b) => {
    let calc = a.order - b.order
    if (calc === 0) calc = (a.timestamp - b.timestamp) * -1
    return calc
  }

  const redirectTo = path => {
    window.location = path
  }

  const renderItensContent = () => {
    if (!products || !products.length) return
    const resultRender = []
    for (const item of products) {
      const timestamp = `${new Date().valueOf()}${item.productId}`
      resultRender.push(
        <div key={timestamp} onClick={() => redirectTo(`/product/${item.id}`)}>
          <GridItem align="center" style={{ cursor: 'pointer' }}>
            <Card plain>
              <GridItem className={classes.itemGrid}>
                <Carousel {...settings}>{renderProductPictures(item.pictures || [])}</Carousel>
              </GridItem>
              <h4 className={classes.cardTitle}>
                {item?.title}
                <br />
                <small className={classes.smallTitle}>
                  {item?.estate} - {item?.city}
                </small>
                <br />
                <small className={classes.smallTitle}>{utils.moneyFormat(item?.value)}</small>
              </h4>
            </Card>
          </GridItem>
        </div>
      )
    }
    return resultRender
  }

  const loadProducts = async pId => {
    let ownerId = undefined
    if (pId) {
      const result = await productApi.getProductInfo(pId)
      if (result?.data?.data) ownerId = result.data.data.owner
    }
    const res = await productApi.listProducts(ownerId)
    if (res?.data?.data) setProducts(res.data.data)
  }

  React.useEffect(() => {
    loadProducts(productId)
  }, [])
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto} align="left">
            <h2 className={classes.title}>Veja Também:</h2>
          </GridItem>
          <Card carousel>
            <Carousel {...containerSettings}>{renderItensContent()}</Carousel>
          </Card>
        </GridContainer>
      </div>
    </div>
  )
}
RelatedProducts.propTypes = {
  history: PropTypes.object,
  productId: PropTypes.string
}
