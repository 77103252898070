import React from 'react'
//  carousel
import Carousel from 'react-slick'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import Button from 'components/CustomButtons/Button.js'
// imgs

import styles from 'assets/jss/material-kit-react/views/componentsSections/carouselStyle.js'
import newsApi from '../../../api/newsApi'

const useStyles = makeStyles(styles)

export default function CategoriesCarousel() {
  const classes = useStyles()
  const [news, setNews] = React.useState([])
  const containerSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(4, news?.length || 0),
    slidesToScroll: 1,
    autoplay: false,
    initialSlide: 0,
    responsive: [
      { breakpoint: 1700, settings: { slidesToShow: Math.min(3, news?.length || 0), slidesToScroll: 1, infinite: true, dots: false } },
      { breakpoint: 1250, settings: { slidesToShow: Math.min(2, news?.length || 0), slidesToScroll: 1, initialSlide: 2 } },
      { breakpoint: 800, settings: { slidesToShow: Math.min(1, news?.length || 0), slidesToScroll: 1, initialSlide: 2 } }
    ]
  }
  const loadNews = async () => {
    const res = await newsApi.getAllNews()
    if (res?.data?.data) {
      setNews(res.data.data)
    }
  }

  const renderIndividualNews = current => {
    return (
      <GridItem align="center" key={current.id}>
        <Card plain>
          <GridItem className={classes.itemGrid}></GridItem>
          <h4 className={classes.cardTitle}>
            {current.title}
            <br />
            <small className={classes.smallTitle}>{current.ownerName}</small>
            <br />
            <Button href={`/post/${current.id}`} simple color="primary" size="lg">
              Ler mais
            </Button>
          </h4>
        </Card>
      </GridItem>
    )
  }

  const renderNews = () => {
    if (!news || !news.length) return null
    return news.map(renderIndividualNews)
  }

  React.useEffect(() => {
    loadNews()
  }, [])
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
            <h2 className={classes.title}>Blog e Notícias</h2>
          </GridItem>
          <Card carousel>
            <Carousel {...containerSettings}>{renderNews()}</Carousel>
          </Card>
        </GridContainer>
      </div>
    </div>
  )
}
