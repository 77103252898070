const keys = {
  token: 'token',
  userId: 'user_id',
  userData: 'user_data'
}

export default {
  get: name => localStorage.getItem(name),
  set: (name, value) => localStorage.setItem(name, value),
  del: name => localStorage.removeItem(name),
  keys,
  clear: () => {
    for (const key in keys) localStorage.removeItem(keys[key])
  },
  getUserData: () => {
    let userData = localStorage.getItem(keys.userData)
    if (!userData || userData === '[object Object]') {
      const token = localStorage.getItem(keys.token)
      if (token && token.split('.').length === 3) {
        try {
          const data = atob(token.split('.')[1])
          userData = JSON.parse(data)
          if (userData) localStorage.setItem(keys.userData, userData)
        } catch (error) {
          return {}
        }
      }
    }
    return userData
  }
}
