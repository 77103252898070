import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import { useParams, Link } from 'react-router-dom'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import NavPills from 'components/NavPills/NavPills.js'
import Parallax from 'components/Parallax/Parallax.js'
import profile from 'assets/img/faces/farmer.jpg'
import styles from 'assets/jss/material-kit-react/views/profilePage.js'
import missingPicture from 'assets/img/missingPicture.png'
import Card from 'components/Card/Card.js'
import Carousel from 'react-slick'
import store from '../../mixins/store'
import userApi from '../../api/userApi.js'
import productApi from '../../api/productApi.js'
import allCategories from '../../content/category.json'
import utils from '../../mixins/utils.js'

const useStyles = makeStyles(styles)
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false
}

export default function ProfilePage(props) {
  const { id } = useParams()
  const classes = useStyles()
  const [userData, setUserData] = React.useState({})

  const [categories, setCategories] = React.useState({})
  const [products, setProducts] = React.useState([])

  const { ...rest } = props
  const imageClasses = classNames(classes.imgRaised, classes.imgRoundedCircle, classes.imgFluid, classes.imgRoundCustom)

  const loadUserData = async () => {
    const userId = id || store.getUserData().id
    const res = await userApi.getUserDataInfo(userId)
    if (res?.data?.data) setUserData(res.data.data)
  }

  const loadUserProducts = async () => {
    const userId = id || store.getUserData().id
    const res = await productApi.listProducts(userId)
    const loadedCategories = []
    if (res?.data?.data) {
      const list = res.data.data
      list.forEach(item => (loadedCategories[item.category] = allCategories[item.category]))
      setProducts(list)
      setCategories(loadedCategories)
    }
  }

  const sortPictures = (a, b) => {
    let calc = a.order - b.order
    if (calc === 0) calc = (a.timestamp - b.timestamp) * -1
    return calc
  }

  const renderProductPictures = pictures => {
    if (!pictures || !pictures.length) {
      return [
        <div key={new Date().valueOf()}>
          <div style={{ overflow: 'hidden' }}>
            <img src={missingPicture} className="slick-image-categories" alt="" />
            <div className="slick-caption"></div>
          </div>
        </div>
      ]
    }
    return pictures.sort(sortPictures).map(picture => (
      <div key={new Date().valueOf()}>
        <div style={{ overflow: 'hidden' }}>
          <img src={picture.path} className="slick-image-categories" alt={picture.fileName} />
          <div className="slick-caption"></div>
        </div>
      </div>
    ))
  }

  const renderItensContent = cat => {
    if (!cat || !products || !products.length) return
    const resultRender = []
    const filtered = products.filter(prod => prod.category === cat)
    for (const item of filtered) {
      const timestamp = `${new Date().valueOf()}${item.productId}`
      resultRender.push(
        <GridItem xs={12} md={6} align="center" key={timestamp}>
          <Link to={`/product/${item.id}`}>
            <Card
              plain
              style={{
                marginTop: '0.2rem',
                marginBottom: '0.2rem',
                cursor: 'pointer'
              }}
            >
              <GridItem className={classes.itemGrid}>
                <Carousel {...settings}>{renderProductPictures(item.pictures || [])}</Carousel>
              </GridItem>
              <h4 className={classes.cardTitle} style={{ margin: '0px', lineHeight: '1rem', fontSize: '100%' }}>
                <div style={{ marginBottom: '0.3rem' }}>{item?.title}</div>
                <small className={classes.smallTitle} style={{ lineHeight: '1rem' }}>
                  <div>
                    {item?.estate} - {item?.city}
                  </div>
                  <div>{utils.moneyFormat(item?.value)}</div>
                </small>
              </h4>
            </Card>
          </Link>
        </GridItem>
      )
    }
    return resultRender
  }

  React.useEffect(() => {
    loadUserData()
    loadUserProducts()
  }, [])
  return (
    <div>
      <Header
        href="/"
        color="transparent"
        brand="Porteira Aberta"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: 'white'
        }}
        {...rest}
      />
      <Parallax small filter image={require('assets/img/colheita.jpg')} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div id="userInfoDetailArea">
          <div className={classes.container}>
            <GridContainer justify="center" align="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <div alt="" className={imageClasses} style={{ backgroundImage: `url('${userData.picture ?? profile}')` }}></div>
                    {/* <img src={`${userData.picture ?? profile}`} alt="" className={imageClasses} /> */}
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>{userData.name}</h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                {userData.description ||
                  `Olá, eu sou o ${userData.name}, bem vindo ao meu perfil, logo abaixo estão localizados os meus produtos,
                separados por categoria!`}
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={Object.keys(categories).map(key => ({
                    tabButton: key,
                    svgIcon: categories[key].icon,
                    tabContent: renderItensContent(key)
                  }))}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
