import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
// @material-ui/icons
import Search from '@material-ui/icons/Search'
import Check from '@material-ui/icons/Check'

import styles from 'assets/jss/material-kit-react/views/componentsSections/basicsStyle.js'
import categories from '../../../content/category.json'

const useStyles = makeStyles(styles)

export default function CategoriesSection(props) {
  const search = useLocation().search
  const categoryQuery = new URLSearchParams(search).get('category')
  const searchTextQuery = new URLSearchParams(search).get('search')

  const classes = useStyles()
  const {
    subCategoryValues,
    setSubCategoryValues,
    categoryValues,
    setCategoryValues,
    setSearchField,
    setSearchTitleField
  } = props.categories
  const { hideCityFilter } = props
  const [fieldValue, setFieldValue] = React.useState('')
  const [fieldTitleValue, setFieldTitleValue] = React.useState('')

  const clearFilters = () => {
    setCategoryValues([])
    setSubCategoryValues([])
    setSearchField('')
    setFieldValue('')
    setFieldTitleValue('')
    setSearchTitleField('')
  }

  const toggleCategory = value => {
    if (categoryValues.includes(value)) {
      setCategoryValues(categoryValues.filter(val => val !== value))
      setSubCategoryValues(subCategoryValues.filter(val => val.indexOf(value) !== 0))
    } else {
      setCategoryValues([...categoryValues, value])
    }
  }

  const toggleSubCategory = (cat, subCat) => {
    const selection = `${cat}:${subCat}`
    if (subCategoryValues.includes(selection)) {
      setSubCategoryValues(subCategoryValues.filter(val => val !== selection))
    } else {
      setSubCategoryValues([...subCategoryValues, selection])
    }
  }

  const showSubCategoriesPlaces = () => {
    return categoryValues.map(cat => (
      <div key={cat}>
        <GridItem>
          <div className={classes.title}>
            <h3>{cat}</h3>
          </div>
        </GridItem>
        <div className="categoryMultiSelectArea" style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem' }}>
          {showSubCategoriesItens(cat)}
        </div>
      </div>
    ))
  }

  const showSubCategoriesItens = category => {
    const subs = categories?.[category]?.['subCategorias']
    if (subs) {
      return Object.keys(subs).map(val => {
        return (
          <FormControlLabel
            key={val}
            control={
              <Checkbox
                tabIndex={-1}
                onClick={() => toggleSubCategory(category, val)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{ label: classes.label, root: classes.labelRoot }}
            label={val}
          />
        )
      })
    }
  }

  const updateFieldTitle = event => {
    if (event.keyCode === 13) setSearchTitleField(fieldTitleValue)
  }

  const updateField = event => {
    if (event.keyCode === 13) setSearchField(fieldValue)
  }

  React.useEffect(() => {
    if (categoryQuery) {
      setCategoryValues([categoryQuery])
    }
    if (searchTextQuery) {
      setFieldTitleValue(searchTextQuery)
      setSearchTitleField(searchTextQuery)
    }
  }, [])

  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xm={12} sm={12}>
          {hideCityFilter ? null : (
            <div>
              <div className={classes.title2}>
                <h3>Buscar</h3>
              </div>
              <div className={classes.title2}>
                <CustomInput
                  white
                  inputRootCustomClasses={classes.inputRootCustomClasses}
                  formControlProps={{
                    className: classes.formControl
                  }}
                  inputProps={{
                    placeholder: 'Buscar',
                    onChange: e => setFieldTitleValue(e.target.value),
                    onKeyDown: updateFieldTitle,
                    value: fieldTitleValue,
                    inputProps: {
                      'aria-label': 'Search',
                      className: classes.searchInput
                    }
                  }}
                />
                <Button justIcon round color="white" onClick={() => setSearchTitleField(fieldValue)}>
                  <Search className={classes.searchIcon} />
                </Button>
              </div>
              <div className={classes.title2}>
                <h3>Localização</h3>
              </div>
              <div className={classes.title2}>
                <CustomInput
                  white
                  inputRootCustomClasses={classes.inputRootCustomClasses}
                  formControlProps={{
                    className: classes.formControl
                  }}
                  inputProps={{
                    placeholder: 'Estado ou cidade',
                    onChange: e => setFieldValue(e.target.value),
                    onKeyDown: updateField,
                    value: fieldValue,
                    inputProps: {
                      'aria-label': 'Search',
                      className: classes.searchInput
                    }
                  }}
                />
                <Button justIcon round color="white" onClick={() => setSearchField(fieldValue)}>
                  <Search className={classes.searchIcon} />
                </Button>
              </div>
            </div>
          )}
          <GridItem>
            <div className={classes.title}>
              <h3>Categorias</h3>
            </div>
            <div className="categoryMultiSelectArea" style={{ display: 'flex', flexDirection: 'column' }}>
              {Object.keys(categories).map(category => {
                return (
                  <FormControlLabel
                    key={category}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => toggleCategory(category)}
                        checked={categoryValues.includes(category)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{ checked: classes.checked, root: classes.checkRoot }}
                      />
                    }
                    classes={{ label: classes.label, root: classes.labelRoot }}
                    label={category}
                  />
                )
              })}
            </div>
          </GridItem>
          {categoryValues.length === 0 ? null : showSubCategoriesPlaces()}
        </GridItem>

        <GridItem>
          <Button simple color="primary" size="lg" onClick={clearFilters}>
            Limpar Filtros
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  )
}
CategoriesSection.propTypes = {
  categories: PropTypes.object,
  hideCityFilter: PropTypes.bool,
  setSearchField: PropTypes.func
}
