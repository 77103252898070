import React from 'react'
import swal from 'sweetalert2'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import Button from 'components/CustomButtons/Button.js'
import Badge from 'components/Badge/Badge.js'

import styles from 'assets/jss/material-kit-react/views/componentsSections/basicsStyle.js'
import newsApi from '../../../api/newsApi'
import Loader from '../../../components/Loader/Loader.js'

const useStyles = makeStyles(styles)

export default function ShowRoomSection(props) {
  const classes = useStyles()
  const navImageClasses = classNames(classes.imgNews, classes.imgRounded, classes.imgRaised)
  const { subCategoryValues, categoryValues, searchField } = props.categories
  const [news, setNews] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const showBadges = () => {
    const cats = categoryValues.filter(cat => !subCategoryValues.find(sub => sub.indexOf(cat) === 0))
    cats.push(...subCategoryValues)
    return cats.map(val => {
      return (
        <Badge key={val} color="primary">
          {val.split(':').join(' > ')}
        </Badge>
      )
    })
  }

  const handleError = async error => {
    if (error?.data?.msg) {
      await swal.fire({ title: 'Falha!', text: error.data.msg, icon: 'error', confirmButtonText: 'OK' })
    }
  }

  const loadNews = async () => {
    const res = await newsApi.getAllNews().catch(handleError)
    if (res?.data?.data) {
      setNews(res.data.data)
    }
    setIsLoading(false)
  }

  const executeFilter = () => {
    const cats = categoryValues.filter(cat => !subCategoryValues.find(sub => sub.indexOf(cat) === 0))
    let filtered = news
    if (cats.length || subCategoryValues.length) {
      filtered = news.filter(item => {
        return cats.includes(item.category) || subCategoryValues.includes(`${item.category}:${item.subCategory}`)
      })
    }
    if (searchField) {
      const lowered = searchField.toLowerCase()
      filtered = filtered.filter(item => {
        return item?.title?.toLowerCase()?.indexOf(lowered) !== -1 || item?.subTitle?.toLowerCase()?.indexOf(lowered) !== -1
      })
    }
    return filtered
  }

  const renderNews = () => executeFilter().map(renderIndividualNews)

  const renderIndividualNews = current => {
    return (
      <GridItem align="center" xs={12} sm={12} md={12} lg={6} key={current.id}>
        <Card>
          <GridItem className={classes.itemGrid}>
            <img src={current.picture} alt="pictureView" className={navImageClasses} />
          </GridItem>
          <h3 className={classes.cardTitle}>{current.title}</h3>
          <h4>
            <small className={classes.smallTitle}>{current.ownerName}</small>
            <br />
            <small className={classes.smallTitle}>{current.subTitle}</small>
            <br />
            <Button href={`/post/${current.id}`} simple color="primary" size="lg">
              Ler mais
            </Button>
          </h4>
        </Card>
      </GridItem>
    )
  }

  React.useEffect(() => {
    loadNews()
  }, [])
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <Loader isLoading={isLoading}>
          <GridContainer>
            <GridItem>{showBadges()}</GridItem>
            {renderNews()}
          </GridContainer>
        </Loader>
      </div>
    </div>
  )
}
ShowRoomSection.propTypes = {
  categories: PropTypes.object,
  history: PropTypes.object,
  showCardButtons: PropTypes.bool,
  ownerFilter: PropTypes.string,
  searchField: PropTypes.string
}
