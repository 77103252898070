import React from 'react'
export default function Icon(props) {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,294.4)">
        <g transform="scale(0.1)">
          <path
            id="path14"
            d="m 3038.98,1706.13 c -84.37,158.44 -325.55,183.05 -620.62,140.45 l 74.68,361.47 -241.56,-56.25 -358.37,-427.34 c -464.24,-139 -921.044,-339.98 -1064.68,-405.12 l -134.731,132.1 185.504,243.19 -200.805,-23.78 -119.843,-86.89 -111.09,108.93 L 0,1595.13 301.91,1330.96 107.387,1095.53 l 214.527,-23.96 122.5,134.69 337.711,-295.49 c 156.93,-20.184 308.015,-30.918 453.145,-33.856 L 547.871,0 1026.29,1.96094 1852.19,911.387 c 413.24,55.078 760.31,166.663 1035.67,277.633 1108.81,446.88 426.5,603.17 151.12,517.11 z m -758.32,394.45 148.16,22.17 -103.42,-291.43 c -23.62,-4.31 -47.49,-8.99 -71.57,-13.99 l 82.92,220.48 -165.77,-238.77 c -36.86,-8.6 -74.08,-17.87 -111.49,-27.71 z m -1587.726,-475.97 85.871,10.57 -127.852,-141.82 -54.738,53.68 z m -388.661,-483.82 -96.89,2.02 151.39,175.61 98.915,-6.05 z M 1787,994.441 1693.13,893.418 933.883,76.3086 704.219,67.0508 1328.31,876.102 l 58.48,75.82 26.39,34.211 410.97,48.287 z M 2861.54,1275.51 C 2531.65,1151 2224.09,1071.7 1952.58,1021.94 l 100.52,110.68 -688.13,-90.24 -74.83,-95.466 C 987.449,934.656 808.453,959.648 808.453,959.648 L 678.16,1078.64 c 189.953,8.25 1124.14,59.94 1751.72,298.07 l -32.47,122.14 c 0,0 -941.03,-301.88 -1761.801,-381.35 l -148.535,135.66 109.328,120.2 -240.949,0.01 -197.523,180.4 282.015,48.87 361.125,-372.79 19.594,17.59 c 1153.586,496.36 1691.976,520.72 1939.386,457.7 -94.78,-54.89 -201.77,-111.15 -244.41,-133.33 50.25,-46.42 71.8,-89.53 98.34,-162.42 56.66,18 213.11,72.78 349.22,166.96 785.65,108.98 -9.12,-265.91 -101.66,-300.84 z m -1286.93,-391.381 83.34,92.844 5.59,6.238 -65.56,-13.113 -37.83,-7.571 -61.06,-82.468 -338.72,-457.461 z m 501.36,205.921 c 0,0 490.53,88.45 798.42,246.99 l -749.02,-197.59 -49.4,-49.4"
          />
        </g>
      </g>
    </svg>
  )
}
