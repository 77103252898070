import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'

import styles from 'assets/jss/material-kit-react/views/landingPageSections/teamStyle.js'

import seuze from 'assets/img/faces/seuze.jpg'

const useStyles = makeStyles(styles)

export default function TeamSection() {
  const classes = useStyles()
  const imageClasses = classNames(classes.imgRaised, classes.imgRoundedCircle, classes.imgFluid, classes.imgRoundCustom)
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Este é nosso time</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem className={classes.itemGrid}>
                <div alt="..." className={imageClasses} style={{ backgroundImage: `url('${seuze}')` }}></div>
                {/* <img src={seuze} alt="..." className={imageClasses} /> */}
              </GridItem>
              <h4 className={classes.cardTitle}>
                José Pereira
                <br />
                <small className={classes.smallTitle}>Fazendeiro</small>
              </h4>
              <CardBody>
                <p className={classes.description}>Detalhes do seu zé, onde trabalhou e o que costuma fazer</p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button justIcon color="transparent" className={classes.margin5}>
                  <i className={classes.socials + ' fab fa-linkedin'} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
