import React from 'react'
import swal from 'sweetalert2'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import { AiOutlineMail } from 'react-icons/ai'
import { MdAccountBox } from 'react-icons/md'
import { MdAssignmentInd } from 'react-icons/md'
import { MdPhoneAndroid } from 'react-icons/md'
import { MdAssignment } from 'react-icons/md'
import Header from '../../components/Header/Header.js'
import HeaderLinks from '../../components/Header/HeaderLinks.js'
import Footer from '../../components/Footer/Footer.js'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Button from '../../components/CustomButtons/Button.js'
import Card from '../../components/Card/Card.js'
import CardBody from '../../components/Card/CardBody.js'
import CardFooter from '../../components/Card/CardFooter.js'
import CustomInput from '../../components/CustomInput/CustomInput.js'
import Loader from '../../components/Loader/Loader.js'
import styles from '../../assets/jss/material-kit-react/views/loginPage.js'
import image from '../../assets/img/colheita.png'
import userApi from '../../api/userApi.js'
import utils from '../../mixins/utils'
import FileUploader from '../../components/FileUploader/FileUploader.js'

import FormControl from '@material-ui/core/FormControl'
import fullStates from '../../content/state.json'
import fullCities from '../../content/cities.json'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(styles)
let lastSignedData = null

export default function ProfileChangePage(props) {
  const [cardAnimation, setCardAnimation] = React.useState('cardHidden')
  const [cpf, setCPF] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [job, setJob] = React.useState('')
  const [name, setName] = React.useState('')
  const [birthday, setBirthday] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [city, setCity] = React.useState('')
  const [estate, setEstate] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(true)
  const [cpfError, setCpfError] = React.useState(false)
  const [emailError, setEmailError] = React.useState(false)
  const [nameError, setNameError] = React.useState(false)
  const [picture, setPicture] = React.useState('')
  const [citiesOptions, setCitiesOptions] = React.useState([])
  const isLogged = utils.isLogged()

  const estateOptions = fullStates.map(val => val.Sigla)

  const changedCity = selectedItem => setCity(selectedItem)

  const changedState = selectedItem => {
    setCity('')
    setEstate(selectedItem)
    const selectedEstate = fullStates.filter(est => est.Sigla === selectedItem)
    const cities = fullCities.filter(innerCity => innerCity.Estado === selectedEstate?.[0]?.ID).map(({ Nome }) => Nome)
    setCitiesOptions(cities)
  }

  const loadUserData = async () => {
    let userData = {}
    const res = await userApi.getUserData()
    if (res?.data?.data) userData = res.data.data
    setCPF(userData.cpf || '')
    setEmail(userData.email)
    setJob(userData.job || '')
    setName(userData.name)
    setBirthday(userData.birthday ? new Date(userData.birthday).toISOString().substr(0, 10) : '')
    setPhone(userData.phone || '')
    setCity(userData.city || '')
    setEstate(userData.estate || '')
    setPicture(userData.picture || '')
    setDescription(userData.description || '')
    setIsLoading(false)

    const selectedEstate = fullStates.filter(est => est.Sigla === userData.estate)
    if (selectedEstate && selectedEstate.length) {
      const cities = fullCities.filter(innerCity => innerCity.Estado === selectedEstate[0].ID).map(({ Nome }) => Nome)
      setCitiesOptions(cities)
    }
  }

  const handleLoginError = error => {
    if (error?.data?.msg) alert(error.data.msg)
  }

  const alert = (msg, type) => swal.fire({ title: '', text: msg, icon: type || 'error', confirmButtonText: 'OK' })

  const executeSave = async () => {
    setCpfError(false)
    setEmailError(false)
    setNameError(false)
    if (!cpf) {
      setCpfError(true)
      return alert('CPF é campo obrigatório')
    }
    if (!email) {
      setEmailError(true)
      return alert('Email é campo obrigatório')
    }
    if (!name) {
      setNameError(true)
      return alert('Nome é campo obrigatório')
    }
    if (!utils.isValidCPF(cpf) && !utils.isValidCNPJ(cpf)) {
      setCpfError(true)
      return alert('CPF inválido')
    }
    if (!utils.isValidEmail(email)) {
      setEmailError(true)
      return alert('Email inválido')
    }

    let birthdayDate = ''
    if (birthday) birthdayDate = new Date(birthday).valueOf()

    const registrationValue = { name, cpf, estate, city, phone, job, birthday: birthdayDate, email, description }
    for (const key in registrationValue) {
      if (!registrationValue[key]) delete registrationValue[key]
    }

    setIsLoading(true)
    const res = await userApi.setUserData(registrationValue).catch(handleLoginError)
    if (res?.data) {
      alert('Seus dados foram atualizados com sucesso!', 'success')
    }
    setIsLoading(false)
  }

  const completedUpload = async () => {
    if (!lastSignedData?.key) {
      alert('Falha ao tentar salvar imagem')
      return
    }
    const profilePicture = `https://${lastSignedData.bucket}/${lastSignedData.key}`
    const res = await userApi.setUserData({ picture: profilePicture })
    setIsLoading(false)
    if (res?.data) setPicture(profilePicture)
  }

  const getUrlToUpload = async ({ file, meta }) => {
    setIsLoading(true)
    const res = await userApi.getUploadUrl(`${new Date().valueOf()}.${utils.getExtension(meta.name)}`, meta.type)
    if (!res?.data) {
      alert('Falha ao tentar enviar imagem para a Porteiraberta')
      setIsLoading(false)
      return { url: '' }
    }
    lastSignedData = res.data.fields
    const formData = new FormData()
    formData.append('Content-Type', file.type)
    Object.entries(res.data.fields).forEach(([k, v]) => formData.append(k, v))
    formData.append('file', file)
    return { url: res.data.url, body: formData }
  }

  const deleteImage = async () => {
    setIsLoading(true)
    const res = await userApi.setUserData({ picture: '' })
    setIsLoading(false)
    if (res?.data) setPicture('')
  }

  React.useEffect(() => {
    if (isLogged) {
      setTimeout(() => setCardAnimation(''), 700)
      loadUserData()
    }
  }, [isLogged])

  const classes = useStyles()
  const { ...rest } = props

  if (!isLogged) return utils.redirect('/login')
  return (
    <div>
      <Loader isLoading={isLoading}>
        <Header absolute color="transparent" brand="Porteira Aberta" rightLinks={<HeaderLinks />} {...rest} />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center'
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Card className={classes[cardAnimation]}>
                  <form className={classes.form}>
                    <p className={classes.divider}>Vamos atualizar algum dado hoje ?</p>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText="Nome Completo"
                            id="name"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'text',
                              value: name,
                              error: nameError,
                              onChange: event => setName(event.target.value),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MdAccountBox className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={5}>
                          <CustomInput
                            labelText="Profissão"
                            id="occupation"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'text',
                              value: job,
                              onChange: event => setJob(event.target.value),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MdAssignment className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={7}>
                          <CustomInput
                            labelText="Email"
                            id="email-address"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'email',
                              value: email,
                              onChange: event => setEmail(event.target.value),
                              error: emailError,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AiOutlineMail className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Data de Nascimento"
                            id="birthday"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'date',
                              value: birthday,
                              onChange: event => setBirthday(event.target.value)
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Telefone"
                            id="tel"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'tel',
                              value: phone,
                              onChange: event => setPhone(event.target.value),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MdPhoneAndroid className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl fullWidth={true} styles={{ marginTop: '1rem' }}>
                            <Autocomplete
                              options={estateOptions}
                              getOptionLabel={item => item?.value || item}
                              onChange={(event, newValue) => changedState(newValue)}
                              value={estate}
                              filterSelectedOptions
                              renderInput={params => (
                                <TextField {...params} variant="standard" label="Estado" placeholder="Selecione seu Estado" />
                              )}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl fullWidth={true} styles={{ marginTop: '1rem' }}>
                            <Autocomplete
                              options={citiesOptions}
                              getOptionLabel={item => item?.value || item}
                              onChange={(event, newValue) => changedCity(newValue)}
                              value={city}
                              filterSelectedOptions
                              renderInput={params => (
                                <TextField {...params} variant="standard" label="Cidade" placeholder="Selecione sua Cidade" />
                              )}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="CPF"
                            id="CPF"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'text',
                              value: cpf,
                              onChange: event => setCPF(event.target.value),
                              error: cpfError,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MdAssignmentInd className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div>
                            <p>Conte-no um pouco sobre você</p>
                            <textarea
                              value={description}
                              rows="4"
                              style={{ minWidth: '100%' }}
                              onChange={event => setDescription(event.target.value)}
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div style={{ width: '150px', height: '150px', textAlign: 'center', fontSize: '60%', zoom: '60%' }}>
                            <FileUploader
                              url={getUrlToUpload}
                              height={150}
                              completedUpload={completedUpload}
                              picture={picture}
                              deleteImage={deleteImage}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                        <Button simple color="primary" size="lg" onClick={executeSave}>
                          Salvar
                        </Button>
                      </GridItem>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </Loader>
    </div>
  )
}
