import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import swal from 'sweetalert2'
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Parallax from 'components/Parallax/Parallax.js'
import PropTypes from 'prop-types'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import CardBody from 'components/Card/CardBody.js'
import Card from 'components/Card/Card.js'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CustomInput from 'components/CustomInput/CustomInput.js'
import FileUploader from '../../components/FileUploader/FileUploader.js'

import styles from 'assets/jss/material-kit-react/views/profilePage.js'
import fullCategories from '../../content/categoryNews.json'
import RichText from '../../components/RichText/RichText.js'
import CustomSelect from '../../components/CustomSelect/CustomSelect.js'
import productApi from '../../api/productApi.js'
import newsApi from '../../api/newsApi.js'
import Loader from '../../components/Loader/Loader.js'

const dashboardRoutes = []

const useStyles = makeStyles(styles)
let lastSignedData = null
let id = undefined

export default function NewPost(props) {
  const classes = useStyles()
  const { ...rest } = props

  const changedTag = selectedItem => {
    setTags(selectedItem)
  }

  const [tags, setTags] = React.useState([])
  const [tabIndex, setTabIndex] = React.useState(0)
  const [subCategoryValue, setSubCategoryValue] = React.useState('')
  const [categoryValue, setCategoryValue] = React.useState('')
  const [tagOptions, setTagOptions] = React.useState([])

  const [title, setTitle] = React.useState('')
  const [subTitle, setSubTitle] = React.useState('')
  const [post, setPost] = React.useState('')
  const [picture, setPicture] = React.useState('')

  const [isLoading, setIsLoading] = React.useState(false)

  const categories = { categoryValue, setCategoryValue, subCategoryValue, setSubCategoryValue }

  const alert = (msg, type) => swal.fire({ title: '', text: msg, icon: type || 'warning', confirmButtonText: 'OK' })

  const savePost = async () => {
    const postData = { title, subTitle, post, picture, tags, category: categoryValue, subCategory: subCategoryValue }
    setIsLoading(true)
    const apiMethod = id ? newsApi.patchNews : newsApi.postNews
    const res = await apiMethod(postData, id).catch(handleError)
    if (res?.data) {
      id = res.data.id
      alert('Postagem salva com sucesso!', 'success')
    }
    setIsLoading(false)
  }

  const updateTagList = () => {
    if (categoryValue && subCategoryValue) {
      setTagOptions(fullCategories?.[categoryValue]?.subCategorias?.[subCategoryValue] || [])
    }
  }

  const completedUpload = () => {
    if (!lastSignedData?.key) {
      alert('Falha ao tentar salvar imagem')
      return
    }
    const postPicture = `https://${lastSignedData.bucket}/${lastSignedData.key}`
    setIsLoading(false)
    setPicture(postPicture)
  }

  const handleError = async error => {
    if (error?.data?.msg) {
      await swal.fire({ title: 'Falha!', text: error.data.msg, icon: 'error', confirmButtonText: 'OK' })
    }
  }

  const getUrlToUpload = async ({ file, meta }) => {
    const { name, type } = meta
    setIsLoading(true)
    const res = await productApi.getUploadUrl('newsPost', name.toLowerCase(), type).catch(handleError)
    if (!res?.data) {
      alert('Falha ao tentar enviar imagem para a Porteiraberta')
      setIsLoading(false)
      return { url: '' }
    }
    lastSignedData = res.data.fields
    const formData = new FormData()
    formData.append('Content-Type', file.type)
    Object.entries(res.data.fields).forEach(([k, v]) => formData.append(k, v))
    formData.append('file', file)
    return { url: res.data.url, body: formData }
  }

  const deleteImage = () => setPicture('')

  React.useEffect(() => {
    updateTagList()
  }, [subCategoryValue])

  return (
    <div>
      <Loader isLoading={isLoading}>
        <Header
          href="/"
          color="transparent"
          routes={dashboardRoutes}
          brand="Porteira Aberta"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: 'white'
          }}
          {...rest}
        />
        <Parallax style={{ marginBottom: '-10rem' }} small={true} filter image={require('assets/img/mailbox.jpg')}></Parallax>
        <div className={classes.container}>
          <Card>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <form className={classes.form}>
                  <div className={classes.container}>
                    <AppBar position="static">
                      <Tabs value={tabIndex} onChange={(e, v) => setTabIndex(v)} aria-label="simple tabs example">
                        <Tab label="Categorização" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                        <Tab label="Tags" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                        <Tab label="Informações de Capa" id="simple-tab-2" aria-controls="simple-tabpanel-2" />
                        <Tab label="Postagem" id="simple-tab-3" aria-controls="simple-tabpanel-3" />
                      </Tabs>
                    </AppBar>
                    <TabPanel value={tabIndex} index={0}>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto} align="center">
                            <CustomSelect
                              align="center"
                              justify="center"
                              categories={categories}
                              data={fullCategories}
                              customText="Selecione a categoria de sua postagem"
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto} align="center">
                            <FormControl fullWidth={true}>
                              <Autocomplete
                                multiple
                                options={tagOptions}
                                getOptionLabel={option => option}
                                onChange={(event, newValue) => changedTag(newValue)}
                                value={tags}
                                filterSelectedOptions
                                renderInput={params => (
                                  <TextField {...params} variant="standard" label="Tags" placeholder="Selecione Tags" />
                                )}
                              />
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText="Título"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                type: 'text',
                                value: title,
                                onChange: event => {
                                  const val = event.target.value
                                  if (val.length <= 150) setTitle(val)
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText="Chamada"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                type: 'text',
                                value: subTitle,
                                onChange: event => {
                                  const val = event.target.value
                                  if (val.length <= 550) setSubTitle(val)
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            Foto da Capa
                            <div style={{ width: '150px', height: '150px', textAlign: 'center', fontSize: '60%', zoom: '60%' }}>
                              <FileUploader
                                url={getUrlToUpload}
                                height={150}
                                completedUpload={completedUpload}
                                picture={picture}
                                deleteImage={deleteImage}
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={3}>
                      <RichText value={post} setValue={setPost} />
                    </TabPanel>
                  </div>
                </form>
              </GridItem>
              <GridItem>
                <hr style={{ margin: '0px' }} />
                <Button
                  simple
                  color="primary"
                  size="lg"
                  onClick={savePost}
                  style={{ width: '100%', backgroundColor: '#cfffe0', margin: '0px', color: '#000000' }}
                >
                  Salvar Postagem
                </Button>
              </GridItem>
            </GridContainer>
          </Card>
        </div>
        <Footer />
      </Loader>
    </div>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.number,
  other: PropTypes.any
}
