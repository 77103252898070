import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'
import Icon from '@material-ui/core/Icon'
import Email from '@material-ui/icons/Email'
import PropTypes from 'prop-types'
import Header from '../../components/Header/Header.js'
import HeaderLinks from '../../components/Header/HeaderLinks.js'
import Footer from '../../components/Footer/Footer.js'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Button from '../../components/CustomButtons/Button.js'
import Card from '../../components/Card/Card.js'
import CardBody from '../../components/Card/CardBody.js'
import CardHeader from '../../components/Card/CardHeader.js'
import CardFooter from '../../components/Card/CardFooter.js'
import CustomInput from '../../components/CustomInput/CustomInput.js'

import styles from 'assets/jss/material-kit-react/views/loginPage.js'

import image from 'assets/img/colheita.png'
import Loader from '../../components/Loader/Loader.js'
import userApi from '../../api/userApi.js'
import store from '../../mixins/store.js'
import { Redirect } from 'react-router'

const useStyles = makeStyles(styles)

export default function LoginPage(props) {
  const [cardAnimation, setCardAnimation] = React.useState('cardHidden')
  const [isLoading, setIsLoading] = React.useState(false)
  const [alertMsg, setAlertMsg] = React.useState('')
  const [login, setLogin] = React.useState('')
  const [password, setPassword] = React.useState('')

  const handleLoginError = error => {
    if (error?.data?.msg) setAlertMsg(error.data.msg)
    setPassword('')
  }
  const goToRegister = () => {
    props.history.push('/user/register')
  }
  const executeLogin = async () => {
    if (!login || !password) {
      setAlertMsg('Digite o login e senha para entrar.')
      return
    }
    passwordFieldRef.current.children[0].blur()
    setIsLoading(true)
    const res = await userApi.doLogin(login, password).catch(handleLoginError)
    if (res?.data?.token) {
      store.set(store.keys.token, res.data.token)
      props.history.push('/categories/')
    }
    setIsLoading(false)
  }

  const passwordFieldRef = React.createRef()
  setTimeout(() => setCardAnimation(''), 700)
  const classes = useStyles()
  const { ...rest } = props

  if (store.get(store.keys.token)) return <Redirect to="/categories/" />
  return (
    <div>
      <Loader isLoading={isLoading}>
        <Snackbar
          open={alertMsg !== ''}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          // TransitionComponent={innerProps => <Slide {...innerProps} direction="down" />}
          onClose={(event, reason) => (reason === 'clickaway' ? '' : setAlertMsg(''))}
        >
          <MuiAlert severity="error">{alertMsg}</MuiAlert>
        </Snackbar>
        <Header absolute color="transparent" brand="Porteira Aberta" rightLinks={<HeaderLinks />} {...rest} />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: 'url(' + image + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'top center'
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[cardAnimation]}>
                  <form className={classes.form}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>Login</h4>
                      <div className={classes.socialLine}>
                        {/* <Button justIcon href="#p" target="_blank" color="transparent" onClick={e => e.preventDefault()}>
                          <i className={'fab fa-facebook'} />
                        </Button>
                        <Button justIcon href="#" target="_blank" color="transparent" onClick={e => e.preventDefault()}>
                          <i className={'fab fa-google-plus-g'} />
                        </Button> */}
                      </div>
                    </CardHeader>
                    <p className={classes.divider}>Ou insira sua conta</p>
                    <CardBody>
                      <CustomInput
                        labelText="Email..."
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: 'email',
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          value: login,
                          onChange: event => setLogin(event.target.value),
                          onKeyUp: event => (event.keyCode === 13 ? passwordFieldRef.current.children[0].focus() : '')
                        }}
                      />
                      <CustomInput
                        labelText="Senha"
                        id="secret"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: 'password',
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>lock_outline</Icon>
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                          value: password,
                          onChange: event => setPassword(event.target.value),
                          onKeyUp: event => (event.keyCode === 13 ? executeLogin() : ''),
                          ref: passwordFieldRef
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button simple color="primary" size="lg" onClick={executeLogin}>
                        Login
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
                <div style={{ textAlign: 'center' }}>
                  <Button simple color="secondary" size="lg" onClick={goToRegister}>
                    Não possui login ?<br />
                    Clique aqui e faça seu registro!
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </Loader>
    </div>
  )
}

LoginPage.propTypes = {
  history: PropTypes.object
}
