import React from 'react'
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
// styles
import styles from 'assets/jss/material-kit-react/views/landingPageSections/aboutStyle.js'
// images
import agroplantacao from 'assets/img/agroplantacao.svg'
import agrotrator from 'assets/img/agrotrator.svg'

const useStyles = makeStyles(styles)

export default function AboutSection() {
  const classes = useStyles()
  const navImageClasses = classNames(classes.imgGallery)
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem align="center">
          <h2 className={classes.title}>Sobre nós</h2>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h5 className={classes.description}>
            Você já parou para pensar na relevância do agronegócio no Brasil? Ele representa mais de 21% do PIB brasileiro! Uma quantia que
            beira a R$ 1,55 trilhão! Nos últimos anos ele tem assumido sua merecida atenção pela mídia, ao ser o setor da economia que
            mantém seu crescimento de forma acelerada, impedindo anos de recessão e erguendo todos os outros setores; ainda toda essa
            responsabilidade ainda é baixa, quando lembramos que o Brasil é conhecido como o celeiro do mundo, responsável por fornecer uma
            grande fração dos alimentos que alimentam os mais de 7,7 bilhões de pessoas que habitam nosso planeta, e que deve chegar a 10
            bilhões em 2050.
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} align="center">
          <img src={agroplantacao} alt="..." className={navImageClasses} />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} align="center">
          <h4 className={classes.title}>A pergunta que fica é, como ajudar o agronegócio a suprir essa demanda?</h4>
          <br />
        </GridItem>
        <GridItem xs={12} sm={12} md={4} align="center">
          <img src={agrotrator} alt="..." className={navImageClasses} />
        </GridItem>
        <GridItem xs={12} sm={12} md={8} justify="right">
          <br />
          <h5 className={classes.description}>
            Vendo esta realidade surgiu a Porteira Aberta, acreditamos que a tecnologia terá um papel fundamental em ajudar a desenvolver
            este setor, assim viemos para interligar as pessoas do campo, proporcionando um local de fácil interação para aqueles que vivem
            deste meio, aproximando desde pequenos agricultores para a venda de seus produtos orgânicos, até grandes corporações que desejam
            vender serviços e maquinários.
          </h5>
        </GridItem>
      </GridContainer>
      <div></div>
    </div>
  )
}
