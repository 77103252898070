import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Parallax from 'components/Parallax/Parallax.js'

import CategoriesSection from './section/categories.js'
import ShowRoomSection from './section/showRoom.js'
import styles from 'assets/jss/material-kit-react/views/profilePage.js'
import store from '../../mixins/store.js'
import utils from '../../mixins/utils'

const useStyles = makeStyles(styles)

export default function MyProductList(props) {
  const classes = useStyles()
  const { ...rest } = props
  const [subCategoryValues, setSubCategoryValues] = React.useState([])
  const [categoryValues, setCategoryValues] = React.useState([])

  const categories = { subCategoryValues, setSubCategoryValues, categoryValues, setCategoryValues }

  React.useEffect(() => {
    if (!utils.isLogged()) props.history.push(`/`)
  }, [])
  return (
    <div>
      <Header
        href="/"
        color="transparent"
        brand="Porteira Aberta"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: 'white'
        }}
        {...rest}
      />
      <Parallax small filter image={require('assets/img/curral.jpg')} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} lg={3}>
              <CategoriesSection hideCityFilter={true} categories={categories} />
            </GridItem>
            <GridItem xs={12} sm={6} md={8} lg={9}>
              <ShowRoomSection
                categories={categories}
                history={props.history}
                showCardButtons={true}
                ownerFilter={store.getUserData().id}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  )
}
MyProductList.propTypes = {
  history: PropTypes.object
}
