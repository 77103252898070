import React from 'react'
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
// styles
import styles from 'assets/jss/material-kit-react/views/landingPageSections/aboutStyle.js'
// img
import logo from 'assets/img/porteirabertaLOGOCOLOR.png'
const useStyles = makeStyles(styles)

export default function PrivacyPolicySection() {
  const classes = useStyles()
  const navImageClasses = classNames(classes.imgGallery)
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem align="center">
          <h2 className={classes.title}>Política de Privacidade Porteira Aberta</h2>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} justify="right">
          <h5 className={classes.description}>Atualizada em 28 de janeiro de 2021.</h5>
          <h5 className={classes.description}>
            Esta plataforma é mantida e operada por Porteira Aberta Soluções Tecnologicas. Nós coletamos e utilizamos alguns dados pessoais
            que pertencem àqueles que utilizam nosso site. Ao fazê-lo, agimos na qualidade de controlador desses dados e estamos sujeitos às
            disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).
          </h5>

          <h5 className={classes.description}>
            Nós cuidaremos com muito zelo da proteção de seus dados pessoais, e, por isso, disponibilizamos esta política de privacidade,
            que contém informações importantes em relação à coleta, armazenamento, utilização e todas as demais formas de tratamento das
            informações pessoais dos clientes/usuários que terão acesso e uso dos nossos serviços através de nossa plataforma.
          </h5>

          <ol className={classes.description}>
            <li> Definições</li>
            <li> Quem deve utilizar de nossa plataforma? </li>
            <li> Quem é o responsavel pelo tratamento dos Dados Pessoais? </li>
            <li> Motivo da coleta dos dados pessoais</li>
            <li> Dados que coletamos </li>
            <li> Cookies da plataforma </li>
            <li> Compartilhamento de dados pessoais com terceiros </li>
            <li> Por quanto tempo seus dados pessoais serão armazenados </li>
            <li> Como tratamos as suas informações de forma segura? </li>
            <li> Bases legais para o tratamento de dados pessoais </li>
            <li> Alterações nesta política </li>
            <li> Fale Conosco </li>
          </ol>

          <h5 className={classes.title}>1. Definições</h5>

          <ul className={classes.description}>
            <li>
              {' '}
              Controlador: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento
              de dados pessoais;
            </li>
            <li> Dado Pessoal: todas as informações relacioanadas a pessoa natural identificada ou identificável;</li>
            <li>
              {' '}
              Operador: pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do
              controlador;
            </li>
            <li> Titular: pessoa natural a quem se referem os dados pessoais que são objeto de tratamento; </li>
            <li>
              {' '}
              Tratamento: toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação,
              utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação
              ou controle da informação, modificação, comunicação, transferência, difusão ou extração.{' '}
            </li>
            <li>
              {' '}
              Cookies: arquivos-texto armazenados pelo navegador de seu computador ou celular. Os cookies têm como finalidade identificar o
              computador ou celular, personalizar os acessos e obter dados de acesso, como páginas navegadas ou links clicados. Um cookie é
              atribuído individualmente a cada equipamento eletrônico, não sendo utilizado para executar programas maliciosos, podendo ser
              lidos apenas pelo servidor que o enviou;
            </li>
          </ul>

          <h5 className={classes.title}>2. Quem deve utilizar de nossa plataforma?</h5>

          <h5 className={classes.description}>
            Nossa plataforma só deve ser utilizada por pessoas com mais de 18 (dezoito) anos de idade. Sendo assim, crianças e adolescentes
            devem utilizá-lo somente se estiverem acompanhados e instruidos por um adulto responsável.
          </h5>

          <h5 className={classes.title}>3. Quem é o responsavel pelo tratamento dos Dados Pessoais?</h5>

          <h5 className={classes.description}>É a Porteira Aberta Soluções Tecnologicas, inscrida no CNPJ/MF nº x, com sede na y.</h5>

          <h5 className={classes.title}>4. Motivo da coleta dos dados pessoais</h5>

          <h5 className={classes.description}>
            Nos termos da Lei Geral de Proteção de Dados (Lei nº 13.709/18), a Porteira Aberta realiza o tratamento de seus dados pessoais
            com finalidades específicas e de acordo com as bases legais previstas na respectiva Lei, tais como: para o devido cumprimento
            das obrigações legais e regulatórias, para o exercício regular de direitos e para a proteção do crédito, bem como sempre que
            necessário para a execução dos contratos firmados com seus clientes ou para atender aos interesses legítimos da Porteira Aberta,
            de seus clientes ou de terceiros. Para qualquer outra finalidade, para a qual o consentimento do titular deve ser coletado, o
            tratamento estará condicionado à manifestação livre, informada e inequívoca do titular.
          </h5>

          <h5 className={classes.description}>
            Porteira Aberta, na condição de controlador dos dados nos termos da legislação, poderá tratar, coletar e armazenar, sempre com a
            estrita observância à Lei, seus dados pessoais e informações cadastrais, financeiras e de operações ativas e passivas e serviços
            contratados para: (i) garantir maior segurança e prevenir fraudes; (ii) assegurar sua adequada identificação, qualificação e
            autenticação; (iii) prevenir atos relacionados à lavagem de dinheiro e outros atos ilícitos; (iv) aperfeiçoar o atendimento e os
            produtos e serviços prestados; (v) fazer ofertas de produtos e serviços adequados e relevantes aos seus interesses e
            necessidades de acordo com o seu perfil; e (vi) outras hipóteses baseadas em finalidades legítimas para a prestação de serviços
            que beneficiem os clientes.
          </h5>

          <h5 className={classes.title}>5. Dados que coletamos</h5>

          <h5 className={classes.description}>
            Nosso site coleta e utiliza dados pessoais de nossos usuários, de acordo com o disposto nesta seção.
          </h5>

          <h5 className={classes.title}>5.1. Dados pessoais fornecidos expressamente pelo usuário</h5>

          <h5 className={classes.description}>
            Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem expressamente ao utilizar nosso site:
          </h5>

          <ul className={classes.description}>
            <li> Nome Completo;</li>
            <li> Profissão ;</li>
            <li> Email; </li>
            <li> Data de Nascimento;</li>
            <li> Telefone; </li>
            <li> Cidade; </li>
            <li> Estado;</li>
            <li> CPF</li>
          </ul>

          <h5 className={classes.description}>
            A coleta destes dados ocorre quando o usuário realiza o seu cadastro na plataforma, sendo este responsável pela veracidade de
            todas as informações fornecidas.
          </h5>

          <h5 className={classes.description}>Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:</h5>

          <ul className={classes.description}>
            <li> Cumprimento de obrigações regulatórias ou legais;</li>
            <li> Exercer direito de defesa em processo judicial, administrativo ou arbitral;</li>
            <li> Cumprir decisões de autoridades, administrativas ou judiciais; </li>
            <li>
              {' '}
              Verificar a identidade do usuário e garantir assim uma maior segurança na utilização da plataforma, assim como uma adoção de
              procedimentos de prevenção à fraudes, com intenção de proteger o titular;
            </li>
            <li>
              {' '}
              Executar ações em virtude de relações pré-contratuais, ou durante a vigência de contratação (ciclo de vida do produto) ou
              pós-contratação (retenção, cobrança, etc);
            </li>
            <li> Analisar dados para melhorar a usabilidade, experiência e interatividade ao utilizar de nossa plataforma; </li>
            <li>
              {' '}
              Fazer ofertas e/ou fornecer recomendações mais assertivas às suas necessidades ou interesses, inclusive mediante campanhas de
              marketing;
            </li>
            <li> Realizar pesquisas de comunicação e marketing de relacionamento, para melhorar nossos produtos e serviços;</li>
            <li> Utilizar cookies, alinhado a esta política;</li>
          </ul>

          <h5 className={classes.description}>
            Use sua conta com consciência, pois tanto ela como seus anúncios poderão ser suspensos ou excluídos pela Porteira Aberta e Você
            poderá ser suspenso, sem aviso prévio, em caso de falsidade nas Informações da Conta, mau uso, violação da legislação, da moral,
            da ordem pública, destes T&C e das Condições Particulares que sejam aplicáveis, bem como utilização do Site para atividades
            ilícitas ou imorais, pela falta de pagamento, ou qualquer atividades que, a critério da Porteira Aberta, não esteja de acordo
            com suas políticas internas. Esta análise da Porteira Aberta não acarreta compromisso, garantia, obrigação ou responsabilidade
            da Porteira Aberta quanto à veracidade das informações e conteúdo inseridos pelos usuários.
          </h5>

          <h5 className={classes.title}>5.2. Dados sensíveis</h5>

          <h5 className={classes.description}>
            Não serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles definidos nos arts. 11 e seguintes da Lei de
            Proteção de Dados Pessoais. Assim, não haverá coleta de dados Sobre origem racial ou étnica, convicção religiosa, opinião
            política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida
            sexual, dado genético ou biométrico, quando vinculado a pessoa natural.
          </h5>

          <h5 className={classes.title}>6. Cookies da plataforma</h5>

          <h5 className={classes.description}>
            Cookies são pequenos arquivos de texto baixados automaticamente em seu dispositivo eletrônico ao utilizar de um site. Estes
            possuem como função a identificação de dispositivos, atividades e preferências de usuários.
          </h5>

          <h5 className={classes.description}>
            Os Cookies não permitem que qualquer arquivo ou informação sejam extraidos do disco rígido do usuário, não sendo possível ainda,
            que, por meio deles, se tenha acesso a informações pessoais que não tenham partido do usuário ou da forma como utiliza recursos
            do site.
          </h5>

          <h5 className={classes.title}>6.1. Cookies do Site</h5>

          <h5 className={classes.description}>
            Os Cookies do site são aqueles enviados ao computador ou dispositivo do usuário e administrados exclusivamente pelo site.
          </h5>

          <h5 className={classes.description}>
            As informações coletadas por meio destes cookies são utilizadas para melhorar e personalizar a experiência do usuário, sendo que
            alguns cookies podem, por exemplo, ser utilizados para lembrar as preferências e escolhas do usuário, bem como para o
            oferecimento de conteúdo personalizado.
          </h5>

          <h5 className={classes.title}>6.2. Gestão de Cookies</h5>

          <h5 className={classes.description}>
            O usuário poderá se opor ao registro de cookies pelo site, bastando que desative esta opção no seu próprio navegador. Mais
            informações sobre como fazer isso em alguns dos principais navegadores utilizados, podem ser acessadas a partir dos seguintes
            links:
          </h5>

          <ul className={classes.description}>
            <li>
              {' '}
              Internet Explorer:
              https://support.microsoft.com/pt-br/windows/microsoft-edge-dados-de-navega%C3%A7%C3%A3o-e-privacidade-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd{' '}
            </li>
            <li> Safari: https://support.apple.com/pt-br/guide/mdm/mdmf7d5714d4/web </li>
            <li> Google Chrome: https://support.google.com/accounts/answer/61416?hl=pt-BR </li>
            <li>
              {' '}
              Mozila Firefox:
              https://support.mozilla.org/pt-BR/kb/desative-cookies-terceiros-impedir-rastreamento?redirectslug=desative-cookies-no-firefox-parar-rastreamento&redirectlocale=pt-BR{' '}
            </li>
          </ul>

          <h5 className={classes.description}>
            A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do site,
            comprometendo seu correto e esperado funcionamento. Outra consequência possível é a remoção das preferências do usuário que
            eventualmente tiverem sido salvas, prejudicando sua experiência.
          </h5>

          <h5 className={classes.title}>6.3. Coleta de dados não previstos expressamente</h5>

          <h5 className={classes.description}>
            Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que
            sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida com fundamento em outra base legal
            prevista em lei.
          </h5>
          <h5 className={classes.description}>
            Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes serão informadas aos usuários do site.
          </h5>

          <h5 className={classes.title}>7. Compartilhamento de dados pessoais com terceiros</h5>

          <h5 className={classes.description}>
            Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é possivel que o façamos para cumprir alguma
            determinação legal ou regulatória, ou, ainda, para cumprir alguma ordem expedida por autoridade pública.
          </h5>

          <h5 className={classes.title}>8. Por quanto tempo seus dados pessoais serão armazenados</h5>

          <h5 className={classes.description}>
            Os dados pessoais coletados pelo site são armazenados e utilizados por período de tempo que corresponda ao necessário para
            atingir as finalidades elencadas neste documento e que considere os direitos de seus titulares, os direitos do controlador do
            site e as disposições legais ou regulatórias aplicáveis.
          </h5>

          <h5 className={classes.description}>
            Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são removidos de nossas bases de dados ou anonimizados,
            salvo nos casos em que houver a possibilidade ou a necessidades de armazenamento em virtude de disposição legal ou regulatória.
          </h5>

          <h5 className={classes.title}>9. Como tratamos seus dados de forma segura?</h5>

          <h5 className={classes.description}>
            Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de
            destruição, perda, extravio ou alteração desses dados.
          </h5>

          <h5 className={classes.description}>
            As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a finalidade do tratamento, os riscos que uma
            eventual violação geraria para os direitos e liberdades do usuário, e os padrões atualmente empregados no mercado por empresas
            semelhantes à nossa.
          </h5>

          <h5 className={classes.description}>Entre as medidas de segurança adotadas por nós, destacamos as seguintes:</h5>

          <ul className={classes.description}>
            <li> Os dados de nossos usuários são armazenados em ambiente seguro; </li>
            <li> Limitamos o acesso aos dados de nossos usuários, de modo que terceiros não autorizados não possam acessá-los; </li>
            <li>
              {' '}
              Utilizamos certicado SSL (Secure Socket Layer), de modo que a transmissão de dados entre os dispositivos dos usuários e nossos
              servidores aconteça de forma criptografada;{' '}
            </li>
            <li> Mantemos registros de todos aqueles que têm, de alguma forma, contato com nossos dados. </li>
          </ul>

          <h5 className={classes.description}>
            Ainda que adotemos tudo o que está ao nosso alcance para evitar incidentes de segurança, é possivel que ocorra algum problema
            motivado exclusivamente por um terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em caso de culpa exclusiva
            do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiros. Assim, embora sejamos, em Geral
            responsáveis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação excepcional como
            essas, sobre as quais não temos nenhum tipo de controle.
          </h5>

          <h5 className={classes.description}>
            De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou dano relevante para qualquer de
            nossos usuários, comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade com
            o disposto na Lei Geral de Proteção de Dados.
          </h5>

          <h5 className={classes.title}>10. Bases legais para o tratamento de dados pessoais</h5>

          <h5 className={classes.description}>
            Uma base legal para o tratamento de dados pessoais nada mais é que um fundamento jurídico, previsto em lei, que o justifica.
            Assim, cada operação de tratamento de dados pessoais precisa ter uma base legal a ela correspondente.
          </h5>

          <h5 className={classes.description}>Nós tratamos os dados pessoais de nossos usuários nas seguintes hipóteses:</h5>

          <ul className={classes.description}>
            <li> Mediante o consentimento do titular dos dados pessoais;</li>
            <li> Para o cumprimento de obrigação legal ou regulatória pelo controlador; </li>
            <li> Quando necessário para atender aos interesses legítimos do controlador ou de terceiro. </li>
          </ul>

          <h5 className={classes.title}>10.1. Consentimento</h5>

          <h5 className={classes.description}>
            Determinadas operações de tratamento de dados pessoais realizadas em nosso site dependerão da prévia concordância do usuário,
            que deverá manifestá-la de forma livre, informada e inequívoca.
          </h5>

          <h5 className={classes.description}>
            O usuário poderá revogar seu consentimento a qualquer momento, sendo que, não havendo hipótese legal que permita ou que demande
            o armazenamento dos dados, os dados fornecidos mediante consentimento serão excluídos.
          </h5>

          <h5 className={classes.description}>
            Além disso, se desejar, o usuário poderá não concordar com alguma operação de tratamento de dados pessoais baseada no
            consentimento. Nestes casos, porém, é possivel que não possa utilizar alguma funcionalidade do site que dependa daquela
            operação. As consequências da falta de consentimento para uma atividade específica são informadas previamente ao tratamento.
          </h5>

          <h5 className={classes.title}>10.2. Cumprimento de obrigação legal ou regulatória pelo controlador</h5>

          <h5 className={classes.description}>
            Algumas operações de tratamento de dados pessoais, sobretudo o armazenamento de dados, serão realizadas para que possamos
            cumprir obrigações previstas em lei ou em outras disposições normativas aplicáveis às nossas atividades.
          </h5>

          <h5 className={classes.title}>10.3. Legítimo Interesse</h5>

          <h5 className={classes.description}>
            Para determinadas operações de tratamento de dados pessoais, nos baseamos exclusivamente em nosso interesse legítimo. Para saber
            mais sobre em quais casos, especificamente, nos valemos desta base legal, ou para obter mais informações sobre os testes que
            fazemos para termos certeza de que podemos utilizá-la, entre em contato com nosso Encarregado de Proteção de Dados Pessoais por
            algum dos canais informados neste Política de Privacidade, na seção &quota;Fale Conosco&quota;.
          </h5>

          <h5 className={classes.title}>11. Alterações nesta política</h5>

          <h5 className={classes.description}>
            A presente versão desta Política de Privacidade foi atualizada pela última vez em: 28/01/2021.
          </h5>

          <h5 className={classes.description}>
            Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais
            alterações feitas em nosso site, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação
            daqueles já existentes.
          </h5>

          <h5 className={classes.description}>Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.</h5>

          <h5 className={classes.title}>12. Fale Conosco</h5>

          <h5 className={classes.description}>
            Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, entre em contato
            com nosso Encarregado de Proteção de Dados Pessoais, por algum dos canais mencionados abaixo:
          </h5>

          <h5 className={classes.description}>E-mail: contato@porteiraberta.com.br</h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} align="center">
          <img src={logo} alt="..." className={navImageClasses} />
        </GridItem>
      </GridContainer>
      <div></div>
    </div>
  )
}
