import React from 'react'
import Reorder from 'react-reorder'
import swal from 'sweetalert2'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import { MdAssignment } from 'react-icons/md'
import { MdDelete } from 'react-icons/md'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Loader from '../../components/Loader/Loader.js'
import PropTypes from 'prop-types'
import Header from 'components/Header/Header.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Footer from 'components/Footer/Footer.js'
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import styles from 'assets/jss/material-kit-react/views/loginPage.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import RichText from '../../components/RichText/RichText.js'
import image from 'assets/img/colheita.png'
import InputLabel from '@material-ui/core/InputLabel'
import productApi from '../../api/productApi.js'
import utils from '../../mixins/utils.js'
import { useParams } from 'react-router-dom'
import store from '../../mixins/store.js'
import FileUploader from '../../components/FileUploader/FileUploader.js'
import FormControl from '@material-ui/core/FormControl'
import fullStates from '../../content/state.json'
import fullCities from '../../content/cities.json'
import fullCategories from '../../content/category.json'

import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(styles)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.number,
  other: PropTypes.any
}
let lastSignedData = null

export default function NewProductPage(props) {
  const { id } = useParams()
  const [tabIndex, setTabIndex] = React.useState(2)
  const [pictures, setPictures] = React.useState([])
  const [cardAnimation, setCardAnimation] = React.useState('cardHidden')
  const [isLoading, setIsLoading] = React.useState(true)
  const [subCategoryValue, setSubCategoryValue] = React.useState('')
  const [categoryValue, setCategoryValue] = React.useState('')

  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [value, setValue] = React.useState('')
  const [quantity, setQuantity] = React.useState('')
  const [city, setCity] = React.useState('')
  const [estate, setEstate] = React.useState('')
  const [tags, setTags] = React.useState([])
  const [citiesOptions, setCitiesOptions] = React.useState([])
  const [tagOptions, setTagOptions] = React.useState([])

  const estateOptions = fullStates.map(val => val.Sigla)
  const categories = { categoryValue, setCategoryValue, subCategoryValue, setSubCategoryValue }

  const classes = useStyles()
  const { ...rest } = props

  const handleLoadProduct = async error => {
    if (error?.data?.msg) {
      await swal.fire({ title: 'Falha!', text: error.data.msg, icon: 'error', confirmButtonText: 'OK' })
    }
  }

  const updateTagList = () => {
    if (categoryValue && subCategoryValue) {
      setTagOptions(fullCategories?.[categoryValue]?.subCategorias?.[subCategoryValue] || [])
      setTags([])
    }
  }

  const changedState = selectedItem => {
    setCity('')
    setEstate(selectedItem)
    const selectedEstate = fullStates.filter(est => est.Sigla === selectedItem)
    const cities = fullCities.filter(innerCity => innerCity.Estado === selectedEstate?.[0]?.ID).map(({ Nome }) => Nome)
    setCitiesOptions(cities)
  }

  const changedCity = selectedItem => setCity(selectedItem)

  const changedTag = selectedItem => {
    setTags(selectedItem)
  }

  const loadProductDetail = async () => {
    const res = await productApi.getProductInfo(id).catch(handleLoadProduct)
    if (res?.data?.data) {
      const userData = store.getUserData()
      if (userData?.id !== res?.data?.data?.owner) {
        props.history.push(`/user/products`)
        return
      }
      const product = res.data.data
      setCategoryValue(product.category)
      setSubCategoryValue(product.subCategory)
      setTitle(product.title)
      setDescription(product.description)
      setValue(Number(product.value))
      setQuantity(product.quantity)
      setCity(product.city)
      setEstate(product.estate)
      setTabIndex(Number(new URLSearchParams(props.location.search).get('tabIndex') || '2'))
      setIsLoading(false)
      setTags(product.tags)
      if (product.pictures && Array.isArray(product.pictures)) {
        setPictures(product.pictures.sort(sortPictures))
      }
      const selectedEstate = fullStates.filter(est => est.Sigla === product.estate)
      if (selectedEstate && selectedEstate.length) {
        const cities = fullCities.filter(innerCity => innerCity.Estado === selectedEstate[0].ID).map(({ Nome }) => Nome)
        setCitiesOptions(cities)
      }
    }
  }

  const alert = (msg, type) => swal.fire({ title: '', text: msg, icon: type || 'warning', confirmButtonText: 'OK' })

  const sortPictures = (a, b) => {
    let calc = a.order - b.order
    if (calc === 0) calc = (a.timestamp - b.timestamp) * -1
    return calc
  }
  const completedUpload = async () => {
    if (!lastSignedData?.key) {
      alert('Falha ao tentar salvar imagem')
      return
    }
    pictures.push({
      path: `https://${lastSignedData.bucket}/${lastSignedData.key}`,
      fileName: lastSignedData.filename,
      timestamp: new Date().valueOf(),
      order: 0
    })
    setPictures(pictures)
    const res = await productApi.updateProduct(id, { pictures }).catch(handleLoadProduct)
    setIsLoading(false)
    if (res?.data?.data) setPictures(res.data.data.pictures.sort(sortPictures))
  }

  const getUrlToUpload = async ({ file, meta }) => {
    const { name, type } = meta
    setIsLoading(true)
    const res = await productApi.getUploadUrl(id, name.toLowerCase(), type).catch(handleLoadProduct)
    if (!res?.data) {
      alert('Falha ao tentar enviar imagem para a Porteiraberta')
      setIsLoading(false)
      return { url: '' }
    }
    lastSignedData = res.data.fields
    const formData = new FormData()
    formData.append('Content-Type', file.type)
    Object.entries(res.data.fields).forEach(([k, v]) => formData.append(k, v))
    formData.append('file', file)
    return { url: res.data.url, body: formData }
  }

  const deletePhoto = async item => {
    const { value: accept } = await swal.fire({
      title: 'Deseja realmente apagar a foto ?',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    })

    if (accept) {
      const newPictureList = pictures.filter(e => e.path !== item.path)
      setPictures(newPictureList)
      setIsLoading(true)
      await productApi.updateProduct(id, { pictures: newPictureList }).catch(handleLoadProduct)
      setIsLoading(false)
    }
  }

  const reorderPictures = async (_event, previousIndex, nextIndex) => {
    const fullList = [...pictures]
    const item = fullList.splice(previousIndex, 1)
    const firstPart = fullList.splice(0, nextIndex)
    const newOrderList = [...firstPart, ...item, ...fullList]
    newOrderList.forEach((val, idx) => (val.order = idx))
    setPictures(newOrderList)
    setIsLoading(true)
    await productApi.updateProduct(id, { pictures: newOrderList }).catch(handleLoadProduct)
    setIsLoading(false)
  }

  const fakeUpdateProductInfo = async () => {
    setIsLoading(true)
    await new Promise(res => setTimeout(res, 500))
    alert('Produto atualizado com sucesso!', 'success')
    setIsLoading(false)
  }

  const updateProductInfo = async () => {
    const productInfo = {
      title,
      description,
      quantity,
      city,
      estate,
      value,
      tags,
      category: categoryValue,
      subCategory: subCategoryValue
    }
    if (productInfo.value) productInfo.value = `${value}`
    utils.clearEmptyValues(productInfo)
    setIsLoading(true)
    const res = await productApi.updateProduct(id, productInfo).catch(handleLoadProduct)
    if (res?.data?.data) {
      alert('Produto atualizado com sucesso!', 'success')
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    setTimeout(() => setCardAnimation(''), 700)
    loadProductDetail()
  }, [])

  React.useEffect(() => {
    updateTagList()
  }, [subCategoryValue])

  if (!utils.isLogged()) return utils.redirect('/login')
  return (
    <div>
      <Loader isLoading={isLoading}>
        <Header absolute color="transparent" brand="Porteira Aberta" rightLinks={<HeaderLinks />} {...rest} />
        <div
          className={classes.pageHeader}
          style={{ backgroundImage: 'url(' + image + ')', backgroundSize: 'cover', backgroundPosition: 'top center' }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Card className={classes[cardAnimation]}>
                  <form className={classes.form}>
                    <GridItem xs={12} sm={12} md={12} className={classes.marginAuto} align="center">
                      <h2 className={classes.title}>Vamos aprimorar o seu produto?</h2>
                    </GridItem>
                    <AppBar position="static">
                      <Tabs value={tabIndex} onChange={(e, v) => setTabIndex(v)} aria-label="simple tabs example">
                        <Tab label="Categorização" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                        <Tab label="Tags" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                        <Tab label="Informações" id="simple-tab-2" aria-controls="simple-tabpanel-2" />
                        <Tab label="Fotos" id="simple-tab-3" aria-controls="simple-tabpanel-3" />
                      </Tabs>
                    </AppBar>
                    <TabPanel value={tabIndex} index={0}>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto} align="center">
                            <CustomSelect align="center" justify="center" categories={categories} data={fullCategories} />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <Button simple color="primary" size="lg" onClick={updateProductInfo}>
                              Salvar
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto} align="center">
                            <FormControl fullWidth={true}>
                              <Autocomplete
                                multiple
                                options={tagOptions}
                                getOptionLabel={option => option}
                                onChange={(event, newValue) => changedTag(newValue)}
                                value={tags}
                                filterSelectedOptions
                                renderInput={params => (
                                  <TextField {...params} variant="standard" label="Tags" placeholder="Selecione Tags" />
                                )}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <Button simple color="primary" size="lg" onClick={updateProductInfo}>
                              Salvar
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText="Nome do Produto ou Serviço"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                type: 'text',
                                value: title,
                                onChange: event => {
                                  const val = event.target.value
                                  if (val.length <= 50) setTitle(val)
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <MdAssignment className={classes.inputIconsColor} />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem>
                            <InputLabel className="classes.textArea">Descrição do Produto/Serviço</InputLabel>
                            <RichText value={description} setValue={setDescription} />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Valor do Produto/Serviço"
                              id="value"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{ type: 'number', value: value, onChange: event => setValue(event.target.value) }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Quantidade"
                              id="quantidade"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{ type: 'text', value: quantity, onChange: event => setQuantity(event.target.value) }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth={true} styles={{ marginTop: '1rem' }}>
                              <Autocomplete
                                options={estateOptions}
                                getOptionLabel={item => item?.value || item}
                                onChange={(event, newValue) => changedState(newValue)}
                                value={estate}
                                filterSelectedOptions
                                renderInput={params => (
                                  <TextField {...params} variant="standard" label="Estado" placeholder="Selecione seu Estado" />
                                )}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth={true} styles={{ marginTop: '1rem' }}>
                              <Autocomplete
                                options={citiesOptions}
                                getOptionLabel={item => item?.value || item}
                                onChange={(event, newValue) => changedCity(newValue)}
                                value={city}
                                filterSelectedOptions
                                renderInput={params => (
                                  <TextField {...params} variant="standard" label="Cidade" placeholder="Selecione sua Cidade" />
                                )}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <Button simple color="primary" size="lg" onClick={updateProductInfo}>
                              Salvar
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={3}>
                      <div style={{ display: 'flex', padding: '2rem' }}>
                        <div style={{ width: '210px', textAlign: 'center' }}>
                          <FileUploader url={getUrlToUpload} completedUpload={completedUpload} />
                        </div>
                        <div className="pictureReorderList">
                          <Reorder
                            reorderId="pictureOderList"
                            lock="vertical"
                            onReorder={reorderPictures}
                            disableContextMenus={true}
                            placeholder={<div style={{ height: '6rem', width: '6rem', backgroundColor: '#a5a5a5' }} />}
                          >
                            {pictures.map(item => (
                              <div key={item.timestamp} style={{ position: 'relative' }}>
                                <img
                                  src={item.path}
                                  style={{ maxWidth: '6rem', maxHeight: '6rem', margin: '0rem 1rem', backgroundColor: '#FFFFFF' }}
                                  alt={item.fileName}
                                />
                                <MdDelete
                                  onMouseDown={event => {
                                    event.stopPropagation()
                                    event.cancelBubble = true
                                    deletePhoto(item)
                                  }}
                                  style={{
                                    backgroundColor: '#FFFFFF',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: '0px',
                                    right: '1rem',
                                    fontSize: '27px',
                                    padding: '0.2rem'
                                  }}
                                />
                              </div>
                            ))}
                          </Reorder>
                        </div>
                      </div>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button simple color="primary" size="lg" onClick={fakeUpdateProductInfo}>
                          Salvar
                        </Button>
                      </GridItem>
                    </TabPanel>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </Loader>
    </div>
  )
}
NewProductPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}
