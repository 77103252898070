import React from 'react'
export default function Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M135.758,344.694c-4.142,0-7.5,3.358-7.5,7.5c0,9.125-7.424,16.548-16.549,16.548s-16.548-7.423-16.548-16.548 s7.423-16.548,16.548-16.548c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5c-17.396,0-31.548,14.152-31.548,31.548 s14.152,31.548,31.548,31.548c17.396,0,31.549-14.152,31.549-31.548C143.258,348.052,139.9,344.694,135.758,344.694z" />
      <path d="M448.387,344.694c-4.142,0-7.5,3.358-7.5,7.5c0,9.125-7.423,16.548-16.548,16.548s-16.548-7.423-16.548-16.548 s7.423-16.548,16.548-16.548c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5c-17.396,0-31.548,14.152-31.548,31.548 s14.152,31.548,31.548,31.548s31.548-14.152,31.548-31.548C455.887,348.052,452.529,344.694,448.387,344.694z" />
      <path d="M492.336,214.306l-78.568-13.095l-34.622-90.017h5.112c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5H272.032 c-12.976,0-23.532,10.557-23.532,23.532v88.694H7.5c-4.142,0-7.5,3.358-7.5,7.5v112.226c0,12.976,10.557,23.532,23.532,23.532 H48.11c-0.001,0.173-0.013,0.343-0.013,0.516c0,35.077,28.537,63.613,63.613,63.613c35.077,0,63.613-28.537,63.613-63.613 c0-0.173-0.012-0.343-0.013-0.516h185.429c-0.001,0.173-0.013,0.343-0.013,0.516c0,35.077,28.537,63.613,63.613,63.613 c35.076,0,63.613-28.537,63.613-63.613c0-0.173-0.012-0.343-0.013-0.516h8.545c8.556,0,15.516-6.96,15.516-15.516v-98.645 C512,225.967,503.73,216.205,492.336,214.306z M397.386,200.404h-85.274c-4.705,0-8.532-3.828-8.532-8.532v-48.613h71.827 L397.386,200.404z M111.709,400.808c-26.805,0-48.613-21.808-48.613-48.613s21.808-48.613,48.613-48.613 c26.806,0,48.613,21.808,48.613,48.613C160.323,379,138.515,400.808,111.709,400.808z M424.339,400.808 c-26.806,0-48.613-21.808-48.613-48.613s21.808-48.613,48.613-48.613s48.613,21.808,48.613,48.613 S451.144,400.808,424.339,400.808z M497,336.162c0,0.285-0.231,0.516-0.516,0.516H486.03c-6.945-27.6-31.966-48.097-61.691-48.097 c-29.725,0-54.746,20.497-61.691,48.097H173.401c-6.945-27.6-31.966-48.097-61.691-48.097c-29.725,0-54.746,20.497-61.691,48.097 H23.532c-4.705,0-8.532-3.828-8.532-8.532V223.42h233.5v88.693c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V119.727 c0-4.705,3.828-8.532,8.532-8.532h91.042l6.563,17.065h-73.558c-4.142,0-7.5,3.358-7.5,7.5v56.113 c0,12.976,10.557,23.532,23.532,23.532h95.573l82.185,13.697c4.131,0.688,7.129,4.228,7.129,8.416v2.967h-24.564 c-4.142,0-7.5,3.358-7.5,7.5v24.048c0,12.976,10.557,23.532,23.532,23.532H497V336.162z M497,280.565h-8.532 c-4.705,0-8.532-3.828-8.532-8.532v-16.548H497V280.565z" />
      <path d="M392.274,240.485h-96.193c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h96.193c4.142,0,7.5-3.358,7.5-7.5 C399.774,243.843,396.416,240.485,392.274,240.485z" />
      <path d="M440.371,240.485h-16.032c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h16.032c4.142,0,7.5-3.358,7.5-7.5 C447.871,243.843,444.513,240.485,440.371,240.485z" />
      <path d="M223.936,240.485H39.564c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h184.371c4.142,0,7.5-3.358,7.5-7.5 C231.435,243.843,228.078,240.485,223.936,240.485z" />
    </svg>
  )
}
