import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Badge from 'components/Badge/Badge.js'
import styles from 'assets/jss/material-kit-react/views/ContentStyle.js'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

export default function ContentSection(props) {
  const classes = useStyles()
  const { news } = props

  const withZero = val => `0${val}`.slice(-2)

  const dateTimeFormat = timestamp => {
    const date = new Date(timestamp)
    return `${withZero(date.getDate())}/${withZero(date.getMonth() + 1)}/${date.getFullYear()} as ${withZero(date.getHours())}:${withZero(
      date.getMinutes()
    )}:${withZero(date.getSeconds())}`
  }
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8} align="center">
          <h2 className={classes.title}>{news.title}</h2>
        </GridItem>

        <GridItem xs={12} sm={12} md={10}>
          <h5 className={classes.description}>
            Postado em {dateTimeFormat(news.createdAt)} - por {news.ownerName}
          </h5>
        </GridItem>

        <GridItem xs={12} sm={12} md={10}>
          <div className={classes.postArea} dangerouslySetInnerHTML={{ __html: news.post }} style={{ color: '#000000' }}></div>
        </GridItem>

        <GridItem xs={12} sm={12} md={10}>
          <br />
          <h5 className={classes.description}>Tags:</h5>
          {news.subCategory ? (
            <Badge color="primary">{`${news.category} > ${news.subCategory}`}</Badge>
          ) : (
            <Badge color="primary">{`${news.category}`}</Badge>
          )}
          {news?.tags?.map(tag => (
            <Badge key={`${tag}_${news.id}`} color="primary">
              {tag}
            </Badge>
          ))}
        </GridItem>
      </GridContainer>
      <div></div>
    </div>
  )
}

ContentSection.propTypes = {
  history: PropTypes.object,
  news: PropTypes.any
}
