import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Parallax from 'components/Parallax/Parallax.js'
// sections for this page
import HeaderLinks from 'components/Header/HeaderLinks.js'
// styles
import CategoriesCarousel from './Sections/CategoriesCarousel'
import RelatedProducts from '../product/section/relatedProducts.js'
// import Organics from './Sections/Organics'
import News from './Sections/News'
// imgs
import agrotech from 'assets/img/agrotech.svg'
import logo from 'assets/img/PorteirabertaLOGO.png'

import styles from 'assets/jss/material-kit-react/views/components.js'

const useStyles = makeStyles(styles)

export default function Main(props) {
  const classes = useStyles()
  const { ...rest } = props
  const navImageClasses = classNames(classes.imgGallery)
  return (
    <div>
      <Header
        color="transparent"
        brand="Porteira Aberta"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: 'white'
        }}
        {...rest}
      />
      <Parallax image={require('assets/img/porteiraberta.jpg')}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <img src={logo} alt="..." className={navImageClasses} />
                {/* <h3 className={classes.subtitle}>A diversidade do campo no alcance de um clique</h3> */}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <CategoriesCarousel />
              </GridItem>

              {/* <GridItem>
                <Organics />
              </GridItem> */}

              <GridItem>
                <RelatedProducts />
              </GridItem>

              <GridItem>
                <img src={agrotech} alt="..." className={navImageClasses} />
              </GridItem>

              <GridItem>
                <News />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
