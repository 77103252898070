import React from 'react'
import swal from 'sweetalert2'
import Carousel from 'react-slick'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
// import Pagination from 'components/Pagination/Pagination.js'
import Card from 'components/Card/Card.js'
import Badge from 'components/Badge/Badge.js'
import Loader from '../../../components/Loader/Loader.js'
import styles from 'assets/jss/material-kit-react/views/componentsSections/basicsStyle.js'
import utils from 'mixins/utils'
import productApi from '../../../api/productApi'
import missingPicture from 'assets/img/missingPicture.png'

const useStyles = makeStyles(styles)

export default function ShowRoomSection(props) {
  const classes = useStyles()
  const { subCategoryValues, categoryValues, searchField, searchTitleField } = props.categories
  const { showCardButtons, ownerFilter } = props
  const [isLoading, setIsLoading] = React.useState(true)
  const [productList, setProductList] = React.useState([])

  const showBadges = () => {
    const cats = categoryValues.filter(cat => !subCategoryValues.find(sub => sub.indexOf(cat) === 0))
    cats.push(...subCategoryValues)
    return cats.map(val => {
      return (
        <Badge key={val} color="primary">
          {val.split(':').join(' > ')}
        </Badge>
      )
    })
  }

  const handleError = async error => {
    if (error?.data?.msg) {
      await swal.fire({ title: 'Falha!', text: error.data.msg, icon: 'error', confirmButtonText: 'OK' })
    }
  }

  const editProduct = productId => {
    props.history.push(`/product/edit/${productId}`)
  }

  const loadProducts = async () => {
    const res = await productApi.listProducts(ownerFilter).catch(handleError)
    setIsLoading(false)
    if (res?.data?.data) {
      setProductList(res.data.data)
    }
  }

  const executeFilter = () => {
    const cats = categoryValues.filter(cat => !subCategoryValues.find(sub => sub.indexOf(cat) === 0))
    let filtered = productList
    if (cats.length || subCategoryValues.length) {
      filtered = productList.filter(product => {
        return cats.includes(product.category) || subCategoryValues.includes(`${product.category}:${product.subCategory}`)
      })
    }
    if (searchField) {
      const lowered = searchField.toLowerCase()
      filtered = filtered.filter(product => {
        return product?.city?.toLowerCase()?.indexOf(lowered) !== -1 || product?.estate?.toLowerCase()?.indexOf(lowered) !== -1
      })
    }
    if (searchTitleField) {
      const lowered = searchTitleField.toLowerCase()
      filtered = filtered.filter(product => product?.title?.toLowerCase()?.indexOf(lowered) !== -1)
    }
    return filtered
  }

  const renderProducts = () => executeFilter().map(renderProduct)

  const cardClicked = product => {
    props.history.push(`/product/${product.id}`)
  }

  const renderEditButton = productId => {
    if (!showCardButtons) return null
    return (
      <div
        style={{ position: 'absolute', bottom: '-1.3rem', width: '100%', cursor: 'pointer' }}
        onClick={() => {
          editProduct(productId)
        }}
      >
        <Card
          style={{
            margin: '0px',
            background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
            color: '#FFFFFF',
            fontWeight: 'bold',
            padding: '0.3rem 0rem'
          }}
        >
          Alterar
        </Card>
      </div>
    )
  }

  const renderProduct = product => {
    return (
      <GridItem key={product.id} align="center" xs={12} sm={12} md={6} lg={4} xl={3}>
        <Card style={{ position: 'relative' }}>
          <GridItem className={classes.itemGrid}>
            <Carousel {...settings}>{renderProductPictures(product.pictures)}</Carousel>
          </GridItem>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              cardClicked(product)
            }}
          >
            <h4 className={classes.cardTitle}>
              <div style={{ lineHeight: '1.4rem', height: '4.5rem' }}>{product.title}</div>
              <div>
                <small className={classes.smallTitle}>
                  {product.city} - {product.estate}
                </small>
              </div>
              <div>
                <small className={classes.smallTitle}>R$ {utils.moneyFormat(product.value)}</small>
              </div>
            </h4>
          </div>
          {renderEditButton(product.id)}
        </Card>
      </GridItem>
    )
  }

  const renderProductPictures = pictures => {
    if (!pictures || !pictures.length) {
      return [
        <div key={new Date().valueOf()}>
          <div style={{ overflow: 'hidden' }}>
            <img src={missingPicture} className="slick-image-categories" alt="" />
            <div className="slick-caption"></div>
          </div>
        </div>
      ]
    }
    return pictures.sort(sortPictures).map(picture => (
      <div key={picture.timestamp}>
        <div style={{ overflow: 'hidden' }}>
          <img src={picture.path} className="slick-image-categories" alt={picture.fileName} />
          <div className="slick-caption"></div>
        </div>
      </div>
    ))
  }

  const sortPictures = (a, b) => {
    let calc = a.order - b.order
    if (calc === 0) calc = (a.timestamp - b.timestamp) * -1
    return calc
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  }

  React.useEffect(() => {
    loadProducts()
  }, [])

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <Loader isLoading={isLoading}>
          <GridContainer>
            <GridItem>{showBadges()}</GridItem>
            {renderProducts()}
          </GridContainer>
          {/* <GridItem align="center">
            <Pagination
              pages={[
                { text: 'VOLTAR' },
                { active: true, text: 1 },
                { text: 2 },
                { text: 3 },
                { text: 4 },
                { text: 5 },
                { text: 'PRÓXIMA' }
              ]}
              color="primary"
            />
          </GridItem> */}
        </Loader>
      </div>
    </div>
  )
}
ShowRoomSection.propTypes = {
  categories: PropTypes.object,
  history: PropTypes.object,
  showCardButtons: PropTypes.bool,
  ownerFilter: PropTypes.string,
  searchField: PropTypes.string
}
