import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import AnnouncementIcon from '@material-ui/icons/Announcement'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import CustomTabs from 'components/CustomTabs/CustomTabs.js'
import Card from 'components/Card/Card.js'
import Carousel from 'react-slick'
import Loader from '../../../components/Loader/Loader.js'
import store from '../../../mixins/store.js'
import productAPI from '../../../api/productApi.js'
import chatApi from '../../../api/chatApi.js'
import userApi from '../../../api/userApi.js'
import utils from '../../../mixins/utils.js'
import missingPicture from 'assets/img/missingPicture.png'

import styles from 'assets/jss/material-kit-react/views/landingPageSections/workStyle.js'
import swal from 'sweetalert2'

const useStyles = makeStyles(styles)
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false
}

const alert = (msg, type) => swal.fire({ title: '', text: msg, icon: type || 'error', confirmButtonText: 'OK' })
const thirdParts = {}
const allProducts = {}

export default function ChatSection() {
  const search = useLocation().search
  const chatWith = new URLSearchParams(search).get('chatWith')
  const chatType = new URLSearchParams(search).get('type') || 'buy'
  const classes = useStyles()
  const [chatMap, setChatMap] = React.useState({})
  const [selectedChat, setSelectedChat] = React.useState(undefined)
  const [products, setProducts] = React.useState({})
  const [thirdPerson, setThirdPerson] = React.useState({})
  const [thirdPartsVal, setThirdPartsVal] = React.useState({})
  const [messageField, setMessageField] = React.useState('')
  const [currentChat, setCurrentChat] = React.useState('')
  const [selectedTab, setSelectedTab] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(true)

  const loadMyChat = async () => {
    const res = await chatApi.getMyChat(chatType).catch(handleError)
    const chats = res?.data?.data
    if (chats) {
      chats.news = chats.news.sort((a, b) => b.news - a.news || b.updatedAt - a.updatedAt)
      chats.old = chats.old.sort((a, b) => b.updatedAt - a.updatedAt)
      if (chatWith) {
        const newChat = { productId: chatWith, clientId: store.getUserData().id }
        const matchOnNews = chats.news.filter(chat => chat.productId === chatWith).length
        const matchOnOld = chats.old.filter(chat => chat.productId === chatWith).length
        if (!matchOnNews && !matchOnOld) {
          chats.news = [newChat, ...chats.news]
        }
        if (matchOnOld) setSelectedTab(1)
        setSelectedChat(newChat)
        loadSelectedChat(newChat, false)
      } else {
        setIsLoading(false)
      }
      setChatMap(chats)
    }
  }

  const executeItemSelection = wantedChat => {
    setIsLoading(true)
    setSelectedChat(wantedChat)
    loadSelectedChat(wantedChat, true)
  }

  const loadSelectedChat = async (chat, reloadThirdPart) => {
    const res = await chatApi.getMessage(chat.productId, chat.clientId).catch(handleError)
    if (res?.data?.data) {
      setCurrentChat(res.data.data)
      if (reloadThirdPart) {
        loadUserInfo(chatType === 'sell' ? res.data.data.clientId : res.data.data.ownerId)
      } else {
        setIsLoading(false)
      }
    }
  }

  const sortPictures = (a, b) => {
    let calc = a.order - b.order
    if (calc === 0) calc = (a.timestamp - b.timestamp) * -1
    return calc
  }

  const renderProductPictures = pictures => {
    if (!pictures || !pictures.length) {
      return [
        <div key={new Date().valueOf()}>
          <div style={{ overflow: 'hidden' }}>
            <img src={missingPicture} className="slick-image-categories" alt="" />
            <div className="slick-caption"></div>
          </div>
        </div>
      ]
    }
    return pictures.sort(sortPictures).map(picture => (
      <div key={new Date().valueOf()}>
        <div style={{ overflow: 'hidden' }}>
          <img src={picture.path} className="slick-image-categories" alt={picture.fileName} />
          <div className="slick-caption"></div>
        </div>
      </div>
    ))
  }

  const loadProduct = async productId => {
    if (!allProducts[productId]) {
      allProducts[productId] = {}
      const res = await productAPI.getProductInfo(productId).catch(handleError)
      if (res?.data?.data) {
        allProducts[productId] = res.data.data
        const thirdPartId = chatType === 'sell' ? selectedChat?.clientId : res.data.data.owner
        allProducts[productId].thirdPartId = thirdPartId
        if (selectedChat?.productId === productId) loadUserInfo(thirdPartId)
        if (!thirdParts[thirdPartId]) {
          loadThirdPartInfo(thirdPartId)
        }
        setProducts(allProducts)
      }
    }
  }

  const loadThirdPartInfo = async thirdPartId => {
    const res = await userApi.getUserDataInfo(thirdPartId).catch(handleError)
    if (res?.data?.data) {
      thirdParts[thirdPartId] = res.data.data
      setThirdPartsVal(thirdParts)
      setChatMap(chatMap)
    }
  }

  const loadUserInfo = async userId => {
    const res = await userApi.getUserDataInfo(userId).catch(handleError)
    if (res?.data?.data) setThirdPerson(res.data.data)
    setIsLoading(false)
  }

  const renderInnerChat = list => {
    if (!list) return
    return list.map(item => {
      loadProduct(item.productId)
      const timestamp = `${new Date().valueOf()}${item.productId}`
      return (
        <GridItem xs={12} md={6} align="center" key={timestamp}>
          <Card
            onClick={() => executeItemSelection(item)}
            plain
            style={{
              boxShadow: selectedChat?.productId === item.productId ? '0px 0px 4px 3px #53ac57' : 'none',
              marginTop: '0.2rem',
              marginBottom: '0.2rem',
              cursor: 'pointer'
            }}
          >
            <GridItem className={classes.itemGrid}>
              <Carousel {...settings}>{renderProductPictures(products[item.productId]?.pictures || [])}</Carousel>
            </GridItem>
            <h4 className={classes.cardTitle} style={{ margin: '0px', lineHeight: '1rem', fontSize: '100%' }}>
              <div style={{ marginBottom: '0.3rem' }}>{products[item.productId]?.title || 'carregando...'}</div>
              <div style={{ marginBottom: '0.3rem' }}>{thirdPartsVal[products[item.productId]?.thirdPartId || '0']?.name}</div>
              <small className={classes.smallTitle} style={{ lineHeight: '1rem' }}>
                <div>
                  {products[item.productId]?.estate || 'carregando...'} - {products[item.productId]?.city || '...'}
                </div>
                <div>{utils.moneyFormat(products[item.productId]?.value)}</div>
              </small>
            </h4>
          </Card>
        </GridItem>
      )
    })
  }

  const renderClientUnread = () => {
    return {
      key: new Date().valueOf(),
      tabName: 'Não lidas',
      tabIcon: AnnouncementIcon,
      tabContent: <GridContainer style={{ width: '100%', minHeight: '10rem' }}>{renderInnerChat(chatMap.news)}</GridContainer>
    }
  }

  const renderClientRead = () => {
    return {
      key: new Date().valueOf(),
      tabName: 'lidas',
      tabIcon: QuestionAnswerIcon,
      tabContent: <GridContainer style={{ width: '100%', minHeight: '10rem' }}>{renderInnerChat(chatMap.old)}</GridContainer>
    }
  }

  const handleError = async error => {
    if (error?.data?.msg) {
      await swal.fire({ title: 'Falha!', text: error.data.msg, icon: 'error', confirmButtonText: 'OK' })
    }
  }

  const withZero = val => `0${val}`.slice(-2)

  const dateTimeFormat = timestamp => {
    const date = new Date(timestamp)
    return `${withZero(date.getDate())}/${withZero(date.getMonth() + 1)}/${date.getFullYear()} as ${withZero(date.getHours())}:${withZero(
      date.getMinutes()
    )}:${withZero(date.getSeconds())}`
  }

  const sendMessage = async () => {
    if (!messageField) return alert('É necessário digitar algo para enviar uma mensagem', 'error')
    const owner = chatType === 'sell' ? store.getUserData().id : thirdPerson.id
    const client = chatType === 'sell' ? thirdPerson.id : store.getUserData().id
    const dataToSend = { productId: selectedChat.productId, ownerId: owner, clientId: client, message: messageField }
    setIsLoading(true)
    const res = await chatApi.postMessage(dataToSend).catch(handleError)
    if (res?.data) {
      await alert('Mensagem enviada', 'success')
      setMessageField('')
      loadSelectedChat(selectedChat, false)
    }
  }

  const getIndividualChatRendered = messageList => {
    const resList = []
    for (const individualMessage of messageList) {
      const { message, isOwner, timestamp } = individualMessage
      const tms = `${new Date().valueOf()}_${timestamp}`
      resList.push(
        <div key={tms} className={(chatType === 'sell' && isOwner) || (chatType === 'buy' && !isOwner) ? 'rightChatMsg' : 'leftChatMsg'}>
          <div>
            <div>{message}</div>
            <div className="dateTime">{dateTimeFormat(timestamp)}</div>
          </div>
        </div>
      )
    }
    return resList
  }

  const renderMessages = () => {
    if (currentChat?.messages?.length) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>{getIndividualChatRendered(currentChat.messages)}</div>
      )
    }
  }

  const renderChatBox = () => {
    return (
      <div style={{ minHeight: '20rem' }}>
        <h3 className={classes.title}>
          Conversa com <a href={`/user/info/${thirdPerson.id}`}>{thirdPerson.name}</a>
        </h3>
        <form>
          <GridContainer justify="flex-end">
            {renderMessages()}
            <CustomInput
              labelText="Sua mensagem"
              id="message"
              formControlProps={{
                fullWidth: true,
                className: classes.textArea
              }}
              inputProps={{
                multiline: true,
                rows: 5,
                value: messageField,
                onChange: e => setMessageField(e.target.value)
              }}
            />
            <GridItem xs={12} sm={12} md={4}>
              <Button color="primary" onClick={sendMessage}>
                Enviar Mensagem
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    )
  }

  React.useEffect(() => {
    loadMyChat()
  }, [])
  return (
    <Loader isLoading={isLoading} style={{ width: '100%' }}>
      <div className={classes.section} style={{ width: '100%' }}>
        <GridContainer justify="center" style={{ width: '100%' }}>
          <GridItem xs={12} sm={12} md={7}>
            <h3 className={classes.title}>Mensagens de {chatType === 'buy' ? 'Compras' : 'Vendas'}</h3>
            <CustomTabs
              headerColor={chatType === 'buy' ? 'success' : 'primary'}
              tabs={[renderClientUnread(), renderClientRead()]}
              selectedTab={selectedTab}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={5}>
            {selectedChat ? renderChatBox() : null}
          </GridItem>
        </GridContainer>
      </div>
    </Loader>
  )
}
