import React from 'react'
import swal from 'sweetalert2'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import { AiOutlineMail } from 'react-icons/ai'
import { MdAccountBox } from 'react-icons/md'
import { MdAssignmentInd } from 'react-icons/md'
import { MdPhoneAndroid } from 'react-icons/md'
import { MdAssignment } from 'react-icons/md'
import Loader from '../../components/Loader/Loader.js'
import PropTypes from 'prop-types'
import Header from 'components/Header/Header.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import styles from 'assets/jss/material-kit-react/views/loginPage.js'
import image from 'assets/img/colheita.png'
import userApi from '../../api/userApi.js'
import utils from '../../mixins/utils'
import FormControl from '@material-ui/core/FormControl'
import fullStates from '../../content/state.json'
import fullCities from '../../content/cities.json'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(styles)

export default function RegisterPage(props) {
  const [cardAnimation, setCardAnimation] = React.useState('cardHidden')
  const [cpf, setCPF] = React.useState('')
  const [cpfError, setCpfError] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [emailError, setEmailError] = React.useState(false)
  const [job, setJob] = React.useState('')
  const [name, setName] = React.useState('')
  const [nameError, setNameError] = React.useState(false)
  const [birthday, setBirthday] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [city, setCity] = React.useState(undefined)
  const [password, setPassword] = React.useState('')
  const [passwordError, setPasswordError] = React.useState(false)
  const [passwordConfirma, setPasswordConfirma] = React.useState('')
  const [passwordConfirmaError, setPasswordConfirmaError] = React.useState(false)
  const [estate, setEstate] = React.useState(undefined)
  const [isLoading, setIsLoading] = React.useState(false)
  const [citiesOptions, setCitiesOptions] = React.useState([])

  const alert = (msg, type) => swal.fire({ title: '', text: msg, icon: type || 'error', confirmButtonText: 'OK' })

  const handleLoginError = error => {
    if (error?.data?.msg) alert(error.data.msg)
  }

  const estateOptions = fullStates.map(val => val.Sigla)

  const changedCity = selectedItem => setCity(selectedItem)

  const changedState = selectedItem => {
    setCity('')
    setEstate(selectedItem)
    const selectedEstate = fullStates.filter(est => est.Sigla === selectedItem)
    const cities = fullCities.filter(innerCity => innerCity.Estado === selectedEstate?.[0]?.ID).map(({ Nome }) => Nome)
    setCitiesOptions(cities)
  }

  const executeRegistration = async () => {
    setCpfError(false)
    setEmailError(false)
    setNameError(false)
    setPasswordError(false)
    setPasswordConfirmaError(false)
    if (!cpf) {
      setCpfError(true)
      return alert('CPF é campo obrigatório')
    }
    if (!email) {
      setEmailError(true)
      return alert('Email é campo obrigatório')
    }
    if (!name) {
      setNameError(true)
      return alert('Nome é campo obrigatório')
    }
    if (!utils.isValidCPF(cpf) && !utils.isValidCNPJ(cpf)) {
      setCpfError(true)
      return alert('CPF inválido')
    }
    if (!utils.isValidEmail(email)) {
      setEmailError(true)
      return alert('Email inválido')
    }
    if (!password) {
      setPasswordError(true)
      return alert('Senha é campo obrigatório')
    }
    if (password !== passwordConfirma) {
      setPasswordError(true)
      setPasswordConfirmaError(true)
      return alert('Senha e Confirmar senha não estão iguais')
    }
    let birthdayDate = ''
    if (birthday) birthdayDate = new Date(birthday).valueOf()

    const registrationValue = { name, cpf, estate, city, phone, job, birthday: birthdayDate, email, password }
    for (const key in registrationValue) {
      if (!registrationValue[key]) delete registrationValue[key]
    }
    setIsLoading(true)
    const res = await userApi.registerUser(registrationValue).catch(handleLoginError)
    if (res?.data) {
      await alert('Cadastro efetuado com sucesso!', 'success')
      props.history.push('/login')
    }
    setIsLoading(false)
  }

  const classes = useStyles()
  const { ...rest } = props

  React.useEffect(() => {
    setTimeout(() => setCardAnimation(''), 700)
  }, [])

  if (utils.isLogged()) return utils.redirect('/user/update')

  return (
    <div>
      <Loader isLoading={isLoading}>
        <Header absolute color="transparent" brand="Porteira Aberta" rightLinks={<HeaderLinks />} {...rest} />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: 'url(' + image + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'top center'
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Card className={classes[cardAnimation]}>
                  <form className={classes.form}>
                    <p className={classes.divider}>Insira seus dados para registro de nova conta:</p>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText="Nome Completo"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'text',
                              value: name,
                              error: nameError,
                              onChange: event => setName(event.target.value),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MdAccountBox className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={5}>
                          <CustomInput
                            labelText="Profissão"
                            id="occupation"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'text',
                              value: job,
                              onChange: event => setJob(event.target.value),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MdAssignment className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={7}>
                          <CustomInput
                            labelText="Email"
                            id="email"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'email',
                              value: email,
                              error: emailError,
                              onChange: event => setEmail(event.target.value),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AiOutlineMail className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Senha de 8 a 20 digitos"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'password',
                              minLength: 8,
                              maxLength: 20,
                              value: password,
                              error: passwordError,
                              onChange: event => setPassword(event.target.value),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Icon className={classes.inputIconsColor}>lock_outline</Icon>
                                </InputAdornment>
                              ),
                              autoComplete: 'off'
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Confirme sua senha"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'password',
                              minLength: 8,
                              maxLength: 20,
                              value: passwordConfirma,
                              error: passwordConfirmaError,
                              onChange: event => setPasswordConfirma(event.target.value),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Icon className={classes.inputIconsColor}>lock_outline</Icon>
                                </InputAdornment>
                              ),
                              autoComplete: 'off'
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Data de Nascimento"
                            id="birthday"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              value: birthday,
                              onChange: event => setBirthday(event.target.value),
                              type: 'date'
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Telefone"
                            id="tel"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'tel',
                              value: phone,
                              onChange: event => setPhone(event.target.value),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MdPhoneAndroid className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl fullWidth={true} styles={{ marginTop: '1rem' }}>
                            <Autocomplete
                              options={estateOptions}
                              getOptionLabel={item => item?.value || item}
                              onChange={(event, newValue) => changedState(newValue)}
                              value={estate}
                              filterSelectedOptions
                              renderInput={params => (
                                <TextField {...params} variant="standard" label="Estado" placeholder="Selecione seu Estado" />
                              )}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl fullWidth={true} styles={{ marginTop: '1rem' }}>
                            <Autocomplete
                              options={citiesOptions}
                              getOptionLabel={item => item?.value || item}
                              onChange={(event, newValue) => changedCity(newValue)}
                              value={city}
                              filterSelectedOptions
                              renderInput={params => (
                                <TextField {...params} variant="standard" label="Cidade" placeholder="Selecione sua Cidade" />
                              )}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="CPF"
                            id="CPF"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'text',
                              value: cpf,
                              error: cpfError,
                              onChange: event => setCPF(event.target.value),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MdAssignmentInd className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button simple color="primary" size="lg" onClick={executeRegistration}>
                        Registrar
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </Loader>
    </div>
  )
}
RegisterPage.propTypes = {
  history: PropTypes.object
}
