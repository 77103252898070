import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import SunEditor from 'suneditor-react'
import swal from 'sweetalert2'

import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import productApi from '../../api/productApi'

import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File

export default function RichText(props) {
  const value = props.value || ''
  const setValue = props.setValue || (() => {})
  const [advanced, setAdvanced] = React.useState(false)
  const editorRef = useRef()
  const getRichOptions = isAdvanced => {
    if (isAdvanced) {
      return [
        ['undo', 'redo', 'removeFormat'],
        ['fontSize', 'formatBlock'],
        ['bold', 'italic', 'underline', 'strike', 'superscript', 'subscript'],
        ['fontColor', 'hiliteColor'],
        ['align', 'outdent', 'indent'],
        ['list', 'lineHeight', 'table', 'horizontalRule'],
        ['image', 'video']
      ]
    } else {
      return [['bold', 'italic', 'underline'], ['fontColor', 'hiliteColor'], ['video']]
    }
  }
  const editorOptions = {
    height: 200,
    buttonList: getRichOptions(true)
  }

  const updateRichType = (event, newValue) => {
    setAdvanced(newValue)
    editorRef.current.editor.setOptions({
      height: 200,
      buttonList: getRichOptions(newValue)
    })
  }

  const alert = (msg, type) => swal.fire({ title: '', text: msg, icon: type || 'warning', confirmButtonText: 'OK' })

  const getUrlToUpload = async file => {
    const { name, type } = file
    const res = await productApi.getUploadUrl('gallery', name.toLowerCase(), type)
    if (!res?.data) {
      return { url: '' }
    }
    const formData = new FormData()
    formData.append('Content-Type', type)
    Object.entries(res.data.fields).forEach(([k, v]) => formData.append(k, v))
    formData.append('file', file)
    return { url: res.data.url, body: formData, fields: res.data.fields }
  }

  const onImageUploadBefore = (files, info, handlers) => {
    if (!files || !files.length === 0) return handlers()
    const file = files[0]
    getUrlToUpload(file).then(dataToUpload => {
      const xhr = new XMLHttpRequest()
      xhr.addEventListener('load', () => {
        handlers({
          result: [
            {
              url: `${dataToUpload.url}/${dataToUpload.fields.key}`,
              name: file.name,
              size: file.size
            }
          ]
        })
      })
      xhr.addEventListener(' error', () => {
        alert('Falha ao tentar enviar imagem para a Porteiraberta')
        handlers()
      })

      xhr.open('POST', dataToUpload.url)
      xhr.send(dataToUpload.body)
    })
  }

  useEffect(() => {
    // const elements = document.querySelectorAll('.se-dialog.sun-editor-common')
    // for (let i = 0; i < elements.length; i++) {
    //   const div = document.createElement('div')
    //   div.className = 'sun-editor'
    //   const innerDiv = document.createElement('div')
    //   innerDiv.className = 'se-container'
    //   innerDiv.appendChild(elements[i])
    //   div.appendChild(innerDiv)
    //   document.getElementById('root').appendChild(div)
    // }
    updateRichType(undefined, advanced)
  }, [])

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Switch style={{ color: '#00FF00' }} checked={advanced} onChange={updateRichType} />}
          label={advanced ? 'Avançado' : 'Normal'}
        />
      </FormGroup>
      <SunEditor
        onImageUploadBefore={onImageUploadBefore}
        setOptions={editorOptions}
        ref={editorRef}
        lang="pt_br"
        setContents={value}
        onChange={setValue}
      />
    </div>
  )
}
RichText.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func
}
