const imagesStyles = {
  imgFluid: {
    maxWidth: '220px',
    height: 'auto'
  },
  imgCategories: {
    maxWidth: '200px',
    height: 'auto'
  },
  imgMessage: {
    maxWidth: '100px',
    height: 'auto'
  },
  imgRounded: {
    borderRadius: '6px !important'
  },
  imgRoundedCircle: {
    borderRadius: '50% !important'
  },
  imgRoundCustom: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '160px',
    minWidth: '160px',
    minHeight: '160px',
    maxHeight: '160px',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transform: 'translate3d(0, -50%, 0)'
  },
  imgRaised: {
    boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  imgGallery: {
    width: '100%',
    marginBottom: '2.142rem'
  },
  imgNews: {
    width: '100%',
    marginBottom: '2.142rem',
    marginTop: '-20px'
  },
  imgCardTop: {
    width: '100%',
    borderTopLeftRadius: 'calc(.25rem - 1px)',
    borderTopRightRadius: 'calc(.25rem - 1px)'
  },
  imgCardBottom: {
    width: '100%',
    borderBottomLeftRadius: 'calc(.25rem - 1px)',
    borderBottomRightRadius: 'calc(.25rem - 1px)'
  },
  imgCard: {
    width: '100%',
    borderRadius: 'calc(.25rem - 1px)'
  },
  imgCardOverlay: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    padding: '1.25rem'
  }
}

export default imagesStyles
