import axios from 'axios'
import jwt from 'jsonwebtoken'
import store from './store'

let timeout = 20000
let baseUrl = process.env.REACT_APP_BASE_API_URL

const setBaseUrl = baseURLValue => (baseUrl = baseURLValue)
const setTimeout = timeoutValue => (timeout = timeoutValue)

const raw = data => {
  axios.defaults.headers.common = {}
  if (store.get(store.keys.token)) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${store.get(store.keys.token)}`
  }
  axios.defaults.baseURL = baseUrl
  return axios({
    method: 'get',
    timeout,
    ...data
  }).catch(errorHandler)
}

const get = (url, params, options) => raw({ method: 'get', url, params, ...(options || {}) })
const post = (url, data, params, options) => raw({ method: 'post', url, data, params, ...(options || {}) })
const put = (url, data, params, options) => raw({ method: 'put', url, data, params, ...(options || {}) })
const patch = (url, data, params, options) => raw({ method: 'patch', url, data, params, ...(options || {}) })
const del = (url, params, options) => raw({ method: 'delete', url, params, ...(options || {}) })

const errorHandler = error => {
  if (!error.response && error.code === 'ECONNABORTED') throw Object({ status: 400, data: { code: 0, msg: 'Tempo limite expirou' } })
  if (!error.response && !error.code)
    throw Object({ status: 400, data: { code: 1, msg: 'Tempo limite de comunicação com o servidor expirou' } })
  if (!error.response) throw Object({ status: 500, data: { code: 2, msg: 'Erro inesperado' } })
  throw error.response
}

const userData = () => {
  const data = store.get(store.keys.userData)
  if (data && data !== 'null') return JSON.parse(data)
  const token = store.get(store.keys.token)
  if (token) {
    try {
      const userInfo = jwt.decode(token)
      store.set(store.keys.userData, JSON.stringify(userInfo))
      return userInfo
    } catch (error) {
      // do nothing
    }
  }
  return {}
}

export default { raw, get, post, put, patch, del, setBaseUrl, setTimeout, userData }
