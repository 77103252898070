import React from 'react'
export default function Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 64 64" viewBox="0 0 64 64" {...props}>
      <path d="m50 22h-31.042l14.749-14.749c.188-.188.293-.442.293-.707s-.105-.52-.293-.707l-2.828-2.828c-1.134-1.134-3.11-1.134-4.243 0l-2.284 2.284 1.414 1.414 2.285-2.284c.377-.378 1.036-.378 1.414 0l2.122 2.121-24.043 24.042-2.121-2.122c-.189-.188-.293-.439-.293-.707s.104-.518.293-.707l18.343-18.343-1.414-1.414-1.373 1.373-1.414-1.414c-1.171-1.171-3.074-1.17-4.243 0l-7.071 7.071c-1.17 1.17-1.17 3.073 0 4.243l1.414 1.414-5.657 5.657c-.567.567-.879 1.32-.879 2.122s.312 1.555.879 2.121l2.828 2.829c.188.186.443.291.708.291s.52-.105.707-.293l5.318-5.318 4.211 32.003c.196 1.486 1.474 2.608 2.974 2.608h22.492c1.5 0 2.778-1.122 2.975-2.609l3.455-26.261-1.983-.261-3.455 26.26c-.066.497-.492.871-.992.871h-22.492c-.5 0-.926-.374-.991-.87l-4.411-33.524 1.606-1.606h31.902l-.641 4.87 1.983.261.79-6c.038-.286-.049-.573-.239-.79-.191-.217-.465-.341-.753-.341zm-40.334-4.849c-.39-.39-.39-1.024 0-1.415l7.071-7.071c.389-.389 1.024-.39 1.415 0l1.414 1.414-8.485 8.485z" />
      <path d="m29 31v22c0 1.654 1.346 3 3 3s3-1.346 3-3v-22c0-1.654-1.346-3-3-3s-3 1.346-3 3zm4 0v22c0 .551-.449 1-1 1s-1-.449-1-1v-22c0-.551.449-1 1-1s1 .449 1 1z" />
      <path d="m21 31v22c0 1.654 1.346 3 3 3s3-1.346 3-3v-22c0-1.654-1.346-3-3-3s-3 1.346-3 3zm4 0v22c0 .551-.449 1-1 1s-1-.449-1-1v-22c0-.551.449-1 1-1s1 .449 1 1z" />
      <path d="m37 31v22c0 1.654 1.346 3 3 3s3-1.346 3-3v-22c0-1.654-1.346-3-3-3s-3 1.346-3 3zm4 0v22c0 .551-.449 1-1 1s-1-.449-1-1v-22c0-.551.449-1 1-1s1 .449 1 1z" />
      <path d="m37.243 13.03-4-1c-.391-.097-.802.049-1.042.37l-3 4c-.178.237-.242.541-.173.83.068.289.261.532.526.665l4 2c.137.069.291.105.446.105h5c.332 0 .642-.165.828-.439s.224-.624.101-.932l-2-5c-.12-.298-.375-.521-.686-.599zm-3.007 4.97-2.717-1.358 1.883-2.51 2.859.715 1.262 3.153z" />
      <path d="m49.854 11.472-.959-1.919c-.202-.404-.651-.622-1.091-.533l-5 1c-.468.093-.804.503-.804.98v2c0 .082.01.163.03.243l1 4c.112.449.516.757.969.757.042 0 .083-.002.125-.008l8-1c.354-.044.657-.273.796-.602s.093-.706-.121-.991zm-5.099 4.426-.755-3.021v-1.057l3.447-.689.658 1.317c.027.054.059.105.095.153l1.966 2.622z" />
      <path d="m44 2h2v2h-2z" />
      <path d="m52.804 8.207 1.219 1.586c2.211-1.698 5.048-2.358 7.781-1.813l.393-1.961c-3.304-.66-6.726.138-9.393 2.188z" />
      <path d="m57 12h2v2h-2z" />
      <path d="m48 7h2c0-1.654 1.346-3 3-3v-2c-2.757 0-5 2.243-5 5z" />
    </svg>
  )
}
