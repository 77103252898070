import React from 'react'
import Carousel from 'react-slick'
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import NavPills from '../../../components/NavPills/NavPills.js'

import categoryContent from '../../../content/category.json'

import styles from 'assets/jss/material-kit-react/views/componentsSections/carouselStyle.js'

const useStyles = makeStyles(styles)

export default function CategoriesCarousel() {
  const classes = useStyles()
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 3,
    autoplay: false,
    initialSlide: 0,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 5, slidesToScroll: 3, infinite: true, dots: false } },
      { breakpoint: 800, settings: { slidesToShow: 3, slidesToScroll: 2, initialSlide: 2 } },
      { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 2, initialSlide: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    ]
  }

  const tabCliqued = category => {
    window.location = `/productList?category=${category}`
  }
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
            <h2 className={classes.title}>Categorias</h2>
          </GridItem>
          <Card carousel>
            <Carousel {...settings}>
              {Object.keys(categoryContent).map(key => {
                return (
                  <div key={key} onClick={() => tabCliqued(key)}>
                    <NavPills
                      alignCenter
                      color="primary"
                      tabs={[
                        {
                          tabButton: key,
                          svgIcon: categoryContent[key].icon
                        }
                      ]}
                    />
                  </div>
                )
              })}
            </Carousel>
          </Card>
        </GridContainer>
      </div>
    </div>
  )
}
