import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import PropTypes from 'prop-types'
import styles from 'assets/jss/material-kit-react/views/landingPageSections/workStyle.js'

const useStyles = makeStyles(styles)

export default function CommentSection(props) {
  const { sendComment } = props
  const [value, setValue] = React.useState('')
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Envie seu comentário</h2>
          <CustomInput
            labelText="Deixe sua mensagem"
            formControlProps={{ fullWidth: true, className: classes.textArea }}
            inputProps={{ multiline: true, rows: 5, value: value, onChange: event => setValue(event.target.value) }}
          />
          <GridItem xs={12} sm={12} md={4}>
            <Button color="primary" onClick={() => sendComment(value, setValue)}>
              Postar
            </Button>
          </GridItem>
        </GridItem>
      </GridContainer>
    </div>
  )
}
CommentSection.propTypes = {
  sendComment: PropTypes.func
}
