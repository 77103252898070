import React from 'react'
import swal from 'sweetalert2'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../../components/Header/Header.js'
import HeaderLinks from '../../components/Header/HeaderLinks.js'
import Footer from '../../components/Footer/Footer.js'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Button from '../../components/CustomButtons/Button.js'
import Card from '../../components/Card/Card.js'
import CardBody from '../../components/Card/CardBody.js'
import CardFooter from '../../components/Card/CardFooter.js'
import CustomInput from '../../components/CustomInput/CustomInput.js'
import Loader from '../../components/Loader/Loader.js'
import styles from '../../assets/jss/material-kit-react/views/loginPage.js'
import image from '../../assets/img/colheita.png'
import userApi from '../../api/userApi.js'
import utils from '../../mixins/utils.js'

const useStyles = makeStyles(styles)

export default function ProfileChangePage(props) {
  const [cardAnimation, setCardAnimation] = React.useState('cardHidden')
  const [password, setPassword] = React.useState('')
  const [passwordNew, setPasswordNew] = React.useState('')
  const [passwordConfirm, setPasswordConfirm] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const handleLoginError = error => {
    if (error?.data?.msg) alert(error.data.msg)
  }

  const alert = (msg, type) => swal.fire({ title: '', text: msg, icon: type || 'warning', confirmButtonText: 'OK' })

  const executeSave = async () => {
    if (!password || !passwordNew || !passwordConfirm) return alert('Preencha os 3 campos para poder atualizar a senha!', 'error')
    if (passwordNew !== passwordConfirm) return alert('Os campos "Nova Senha" e "Confirmar Senha" estão diferentes', 'error')
    setIsLoading(true)
    const res = await userApi.setUserPassword(password, passwordNew, passwordConfirm).catch(handleLoginError)
    if (res?.data) {
      alert('Senha atualizada com sucesso!', 'success')
      setPassword('')
      setPasswordNew('')
      setPasswordConfirm('')
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    setTimeout(() => setCardAnimation(''), 700)
  }, [])

  const classes = useStyles()
  const { ...rest } = props

  if (!utils.isLogged()) return utils.redirect('/login')
  return (
    <div>
      <Loader isLoading={isLoading}>
        <Header absolute color="transparent" brand="Porteira Aberta" rightLinks={<HeaderLinks />} {...rest} />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center'
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Card className={classes[cardAnimation]}>
                  <form className={classes.form}>
                    <p className={classes.divider}>Vamos atualizar a segurança da sua conta ?</p>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText="Senha Atual"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: 'password', value: password, onChange: event => setPassword(event.target.value) }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText="Nova Senha"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: 'password', value: passwordNew, onChange: event => setPasswordNew(event.target.value) }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText="Confirmar Senha"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: 'password',
                              value: passwordConfirm,
                              onChange: event => setPasswordConfirm(event.target.value)
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button simple color="primary" size="lg" onClick={executeSave}>
                        Atualizar Senha
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </Loader>
    </div>
  )
}
