import React from 'react'
import swal from 'sweetalert2'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import Badge from 'components/Badge/Badge.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Parallax from 'components/Parallax/Parallax.js'
import profile from 'assets/img/faces/farmer.jpg'
import ProductPictures from './section/productPictures.js'
import CommentForm from './section/commentForm.js'
import CommentList from './section/commentList.js'
import RelatedProducts from './section/relatedProducts.js'
import Loader from '../../components/Loader/Loader.js'
import styles from 'assets/jss/material-kit-react/views/profilePage.js'
import productApi from '../../api/productApi.js'
import utils from '../../mixins/utils.js'
import userApi from '../../api/userApi.js'
import store from '../../mixins/store.js'
import commentApi from '../../api/commentApi.js'

const ChatIcon = require(`assets/icons/chat.js`).default

const useStyles = makeStyles(styles)

export default function ProductPage(props) {
  const { id } = useParams()
  const [isLoading, setIsLoading] = React.useState(true)
  const [productDetail, setProductDetail] = React.useState({})
  const [userName, setUserName] = React.useState('')
  const [ownerId, setOwnerId] = React.useState('')
  const [userPicture, setUserPicture] = React.useState(profile)
  const [showCommentForm, setShowCommentForm] = React.useState(false)
  const [lastLoadTime, setLastLoadTime] = React.useState(0)

  const classes = useStyles()
  const { ...rest } = props
  const imageClasses = classNames(classes.imgRaised, classes.imgRoundedCircle, classes.imgFluid, classes.imgRoundCustom)

  const sendComment = async (msg, setField) => {
    if (!msg || msg.length < 10) return alert('Mensagem muito curta!')
    setIsLoading(true)
    const res = await commentApi.postComment('product', productDetail.owner, id, msg).catch(handleSaveCommentError)
    if (res?.data) {
      await alert('Comentário enviado!', 'success')
      setLastLoadTime(new Date().valueOf())
      setField('')
    }
    setIsLoading(false)
  }

  const denounceComment = async commentId => {
    const result = await swal.fire({
      input: 'textarea',
      inputLabel: 'Deseja realmente denunciar este comentário?',
      inputPlaceholder: 'Descreva aqui o motivo...',
      inputAttributes: {
        'aria-label': 'Descreva aqui o motivo'
      },
      showCancelButton: true,
      confirmButtonText: 'Denunciar'
    })

    if (result.isConfirmed) {
      const detail = result.value
      if (!detail.trim()) return alert('É necessário informar o motivo da denuncia.')
      setIsLoading(true)
      await commentApi.denounceComment(commentId, detail)
      setLastLoadTime(new Date().valueOf())
      alert('Denuncia enviada! Em breve nossa equipe irá fazer uma análise!', 'success')
      setIsLoading(false)
    }
  }

  const deleteComment = async commentId => {
    const result = await swal.fire({
      title: 'Deseja realmente apagar seu comentário?',
      showCancelButton: true,
      confirmButtonText: 'Sim'
    })
    if (result.isConfirmed) {
      setIsLoading(true)
      await commentApi.deleteComment(commentId)
      setLastLoadTime(new Date().valueOf())
      alert('Comentário apagado!', 'success')
      setIsLoading(false)
    }
  }

  const alert = (msg, type) => swal.fire({ title: '', text: msg, icon: type || 'error', confirmButtonText: 'OK' })

  const handleSaveCommentError = async error => {
    console.log(error)
    if (error?.data?.msg) {
      await swal.fire({ title: 'Falha!', text: error.data.msg, icon: 'error', confirmButtonText: 'OK' })
    }
  }

  const handleLoadProduct = async error => {
    if (error?.data?.msg) {
      await swal.fire({ title: 'Falha!', text: error.data.msg, icon: 'error', confirmButtonText: 'OK' })
      props.history.push(`/`)
    }
  }

  const loadProductDetail = async () => {
    const res = await productApi.getProductInfo(id).catch(handleLoadProduct)
    if (res?.data?.data) {
      setProductDetail(res.data.data)
      setShowCommentForm(utils.isLogged() && store?.getUserData()?.id !== res.data.data.owner)
      setOwnerId(res.data.data.owner)
      const userRes = await userApi.getUserDataInfo(res.data.data.owner).catch(err => console.log(err))
      if (userRes?.data?.data) {
        setUserName(userRes.data.data.name)
        setUserPicture(userRes.data.data.picture ?? profile)
        setLastLoadTime(new Date().valueOf())
      }
    } else {
      await swal.fire({ title: 'Falha!', text: 'Falha ao tentar carregar informações', icon: 'error', confirmButtonText: 'OK' })
      props.history.push(`/`)
    }
    setIsLoading(false)
  }

  const renderTags = () => {
    if (!productDetail?.tags?.length) return null
    return productDetail.tags.map(tag => <Badge color="primary" key={`${tag}_${new Date().valueOf()}`}>{`${tag}`}</Badge>)
  }

  React.useEffect(() => {
    loadProductDetail()
  }, [])
  return (
    <div>
      <Loader isLoading={isLoading}>
        <Header
          href="/index.html"
          color="transparent"
          brand="Porteira Aberta"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: 'white'
          }}
          {...rest}
        />
        <Parallax small filter image={require('assets/img/curral.jpg')} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <div alt="" className={imageClasses} style={{ backgroundImage: `url('${userPicture ?? profile}')` }}></div>
                  </div>
                </div>
                <div>
                  <div className={classes.name} style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <h2 className={classes.title} style={{ flex: '1 0 0', textAlign: 'center', marginTop: '1rem' }}>
                      <Tooltip
                        title={
                          <div>
                            Quer ver mais sobre {userName}?<br /> Clique e visite seu perfil...
                          </div>
                        }
                        arrow
                      >
                        <a href={`/user/info/${ownerId}`}>{userName || 'Carregando...'}</a>
                      </Tooltip>
                    </h2>
                    {showCommentForm && (
                      <div style={{ marginLeft: '1rem', position: 'absolute', right: '0px' }}>
                        <span style={{ zoom: '130%', cursor: 'pointer' }}>
                          <Tooltip title={`Vamos escrever uma msg para ${userName}?`} placement="right-end">
                            <div style={{ height: '150px', width: '150px', textAlign: 'right' }}>
                              <ChatIcon
                                className="svgCategoryIcon"
                                onClick={() => (window.location = `/user/message?chatWith=${productDetail.id}`)}
                              />
                            </div>
                          </Tooltip>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <ProductPictures productDetail={productDetail} />
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <h2> {productDetail.title} </h2>
                <h4>
                  {productDetail.city} - {productDetail.estate}
                </h4>
                <h5> R$ {utils.moneyFormat(productDetail.value)} </h5>
                <div className="se-container" style={{ textAlign: 'left' }}>
                  <div className="se-wrapper">
                    <div className="se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable">
                      <div dangerouslySetInnerHTML={{ __html: productDetail.description }}></div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <h5> Ficou interessado? Fale agora com o vendedor!</h5>
                <Button href={`/user/message?chatWith=${productDetail.id}`} simple color="primary" size="lg">
                  Mensagem para {userName}
                </Button>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center" className={classes.navWrapper}>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.name}>
                  <h3 className={classes.title}>Características Principais:</h3>
                </div>
              </GridItem>
              <GridItem>
                <Badge color="primary">{`${productDetail.category} > ${productDetail.subCategory}`}</Badge>
                {renderTags()}
              </GridItem>
            </GridContainer>

            <RelatedProducts productId={id} />
            <CommentList
              userName={userName}
              owner={productDetail.owner}
              refId={id}
              lastLoadTime={lastLoadTime}
              type="product"
              deleteComment={deleteComment}
              denounceComment={denounceComment}
            />
            {showCommentForm ? <CommentForm sendComment={sendComment} /> : null}
          </div>
        </div>
        <Footer />
      </Loader>
    </div>
  )
}
ProductPage.propTypes = {
  history: PropTypes.object
}
