import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import styles from 'assets/jss/material-kit-react/components/customSelectStyle.js'
const useStyles = makeStyles(styles)

export default function CustomSelect(props) {
  const [categoryOptions, setCategoryOptions] = React.useState([])
  const [subCategoryOptions, setSubCategoryOptions] = React.useState([])
  const { subCategoryValue, setSubCategoryValue, categoryValue, setCategoryValue } = props.categories
  const categoryContent = props.data
  const loadCategoryContent = function () {
    const cats = []
    for (const key in categoryContent) {
      cats.push(
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      )
    }
    setCategoryOptions(cats)
  }

  const loadSubCategoryContent = function () {
    if (categoryValue) {
      const cats = []
      for (const key in categoryContent[categoryValue]?.subCategorias || []) {
        cats.push(
          <MenuItem key={key} value={key}>
            {key}
          </MenuItem>
        )
      }
      setSubCategoryOptions(cats)
    }
  }

  const changeSelectOptionHandler = event => {
    setCategoryValue(event.target.value)
    setSubCategoryValue('')
  }

  const changeSubCategoriesOptionHandler = event => {
    setSubCategoryValue(event.target.value)
  }

  const classes = useStyles()
  React.useEffect(() => {
    loadCategoryContent()
    return
  }, [])
  React.useEffect(() => {
    loadSubCategoryContent()
    return
  }, [categoryValue])
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
        <h4 className={classes.title}>{props.customText || 'Selecione a categoria de seu produto:'}</h4>
      </GridItem>
      <GridItem>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Categorias
          </InputLabel>
          <Select value={categoryValue} onChange={changeSelectOptionHandler}>
            {categoryOptions}
          </Select>
        </FormControl>
        {categoryValue !== '' && (
          <div>
            <br /> <br /> <br />
            <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
              <h4 className={classes.title}>Subcategoria</h4>
            </GridItem>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="sub-select" className={classes.selectLabel}>
                Subcategorias
              </InputLabel>
              <Select value={subCategoryValue} onChange={changeSubCategoriesOptionHandler}>
                {subCategoryOptions}
              </Select>
            </FormControl>
          </div>
        )}
      </GridItem>
    </GridContainer>
  )
}

CustomSelect.propTypes = {
  categories: PropTypes.object,
  customText: PropTypes.string,
  data: PropTypes.any
}
